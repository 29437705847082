import React, { useEffect, useState } from "react";
import "@fontsource/alex-brush";
import "@fontsource/sacramento";
import colors from "../../../colors";
import EditButtonCircle from "../../../common/EditButtonCircle";
import { CollageEditModal } from "./CollageEditModal";
import "@fontsource-variable/lora";
import { EditPageState } from "../../state/EditPageState";
import { observer } from "mobx-react-lite";
import { EditableH1 } from "../../editable/EditableH1";
import { EditableDiv } from "../../editable/EditableDiv";
import { css } from "@emotion/css";
import { EditPageItem } from "../../EditPageItem";
import { AnniversaryTemplateBehavior, useAnniversaryTemplateBehavior } from "./useAnniversaryTemplate.behavior";
import useEditBehavior from "./useEditBehavior";
import Tour from "reactour";

type Props = {
  state: EditPageState;
  isWarnEmptyFields: boolean;
};

export const AnniversaryTemplate: React.FC<Props> = observer(({ state, isWarnEmptyFields }) => {
  const { handleEditCollageItemClick, handleEditModalClose, handleCalendarTextChange, selectedCollageItem }: AnniversaryTemplateBehavior =
    useAnniversaryTemplateBehavior(state);
  const {
    handleDragStart,
    handleDragOver,
    handleDragEnd,
    handleDrop,
    handleUpClick,
    handleDownClick,
    isDndTarget,
    handleQuoteChange,
    handleImageChange,
    handleDeleteItem,
    focusedIndex,
  } = useEditBehavior(state);
  const [isTourOpen, setIsTourOpen] = useState(false);

  useEffect(() => {
    !localStorage.getItem("isAnniversaryTourOpen") && setIsTourOpen(true);
  }, []);

  const anniversaryTextStyles = css`
    font-family: "Alex Brush", sans-serif;
    font-size: 4em;
    color: ${colors.white2};
    text-align: center;
    padding: 5px;
    margin-top: 5px;
    line-height: 1.2;
    width: 100%;
    box-sizing: border-box;
  `;

  const guideTextStyle = css`
    color: ${colors.grayDark2};
    line-height: 1.5em;
  `;

  function handleGuideClose() {
    localStorage.setItem("isAnniversaryTourOpen", "true");
    setIsTourOpen(false);
  }

  return (
    <div className="AnniversaryTemplate">
      <Tour
        badgeContent={(curr, tot) => `${curr} of ${tot}`}
        steps={[
          {
            selector: ".AddressBar",
            content: (
              <b className={guideTextStyle}>
                Your website is live! <br />
                You can change the URL as you want.
              </b>
            ),
            style: {
              padding: "40px",
            },
          },
          {
            selector: ".AnniversaryTemplate-CollageItem-Edit-Button",
            content: <b className={guideTextStyle}>Change photos and add texts.</b>,
            style: {
              padding: "40px",
            },
          },
          {
            selector: "#add-item-circle-button",
            content: <b className={guideTextStyle}>Add more photos and texts as you want.</b>,
            style: {
              padding: "40px",
            },
          },
        ]}
        isOpen={isTourOpen}
        onRequestClose={handleGuideClose}
      />
      <div className="AnniversaryTemplate-Collage">
        {state.items
          .filter((item, i) => i < 4)
          .map((item, index) => {
            return (
              <div key={item.key} onClick={() => handleEditCollageItemClick(index)} className="AnniversaryTemplate-CollageItem">
                <img className="Collage-Photo" src={item.data} alt="collage item" />
                <div
                  onClick={() => handleEditCollageItemClick(index)}
                  style={{ position: "absolute", bottom: 0, right: 0 }}
                  className="AnniversaryTemplate-CollageItem-Edit-Button"
                >
                  <EditButtonCircle size="small" onClick={() => handleEditCollageItemClick(index)} />
                </div>
              </div>
            );
          })}
        {selectedCollageItem !== null && <CollageEditModal state={state} selectedItemIndex={selectedCollageItem} onClose={handleEditModalClose} />}
      </div>
      <div className="AnniversaryTemplate-Bottom-Text">
        <EditableH1
          textKey="anniversary-bottom-text"
          className="AnniversaryTemplate-Calendar"
          text={state.items[12].data}
          onChange={handleCalendarTextChange}
        />
        <EditableDiv
          textKey="anniversary-text"
          onChange={(text) => state.updateItem(13, text)}
          className={anniversaryTextStyles}
          text={state.items[13].data}
        />
      </div>
      <div className="AnniversaryTemplate-Items">
        {state.items
          .filter((item, i) => i > 13)
          .map((item, index) => {
            return (
              <EditPageItem
                key={item.key}
                data={item.data}
                index={index + 14}
                isWarnEmpty={isWarnEmptyFields}
                onDragStart={handleDragStart}
                onDragOver={handleDragOver}
                onDragEnd={handleDragEnd}
                onDrop={handleDrop}
                onUpClick={handleUpClick}
                onDownClick={handleDownClick}
                isDndTarget={isDndTarget}
                type={item.type}
                onQuoteChange={handleQuoteChange}
                onImageChange={handleImageChange}
                onDeleteItem={handleDeleteItem}
                focusedIndex={focusedIndex}
              />
            );
          })}
      </div>
      <style>
        {`
        .Collage-Photo {
          width: 48.5vw;
          height: 40vw;
          max-height: 300px;
          max-width: 300px;
          object-fit: contain;
          background-color: ${colors.white2};
          border-radius: 0.25rem
        }
        .AnniversaryTemplate-CollageItem-Edit-Button {
          position: absolute;
        }
        .AnniversaryTemplate {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          top: 0;
          padding: 0;
          max-width: 610px;
        }
        .AnniversaryTemplate-Collage {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          width: 100%;
          box-sizing: border-box;
          gap: 3px;
          position: relative;
          box-sizing: border-box;
          flex-basis: 50%;
          max-width: 700px;
        }
        .AnniversaryTemplate-CollageItem {
          display: flex;
          align-items: center;
          justify-content: center;
          width: calc(50% - 1.5px);
          border-radius: 0.25rem;
          -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
          filter: grayscale(100%);
          border: 1px dashed var(--pink-color-light);
          padding: 0px;
          box-sizing: border-box;
          height: fit-content;
          width: fit-content;
          position: relative;
          cursor: pointer;
        }

        @keyframes rotate {
          0% {
            transform: rotate(0);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        .AnniversaryTemplate-Bottom-Text {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-top: 15px;
          gap: 1em;
        }
        .AnniversaryTemplate-Items {
          margin-top: 2em;
          width: 100%;
          box-sizing: border-box;
        }
      `}
      </style>
    </div>
  );
});
