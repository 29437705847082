import React, { useEffect, useRef, useState } from "react";
import colors from "../colors";

type Props = {
  defaultText: string;
  onChange: (text: string) => void;
  onBlur: () => void;
  testId?: string;
  autoFocus?: boolean;
  placeholder?: string;
  minHeight?: string;
  isError?: boolean;
  className?: string;
};
const TextArea: React.FC<Props> = ({
  defaultText,
  onChange,
  onBlur,
  testId = "text-area",
  autoFocus = false,
  placeholder = "",
  minHeight = "2vh",
  isError = false,
  className = "",
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (!autoFocus) return;
    textAreaRef.current?.focus();
  }, [autoFocus]);

  const [rows, setRows] = useState(1);
  const textAreaHeightPadding = 10;

  function handleTextChange(event: React.ChangeEvent<HTMLTextAreaElement>): void {
    const value = event.target.value;
    onChange(value);
    setRows(1);
  }

  useEffect(
    function () {
      const scrollHeight = textAreaRef.current?.scrollHeight ?? 1;
      // clientHeight is always 1 row height + 2x padding
      // because we are setting rows to 1 when user types
      const clientHeight = textAreaRef.current?.clientHeight ?? 1;
      const calculatedRows = Math.round(((scrollHeight - 2 * textAreaHeightPadding) / (clientHeight - 2 * textAreaHeightPadding)) * rows);
      setRows(calculatedRows);
    },
    [textAreaRef.current?.value]
  );

  function handleOnFocus(e: React.FocusEvent<HTMLTextAreaElement>) {
    e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);
  }

  return (
    <>
      <textarea
        placeholder={placeholder}
        className={className}
        onFocus={handleOnFocus}
        onBlur={onBlur}
        defaultValue={defaultText}
        data-testid={testId}
        ref={textAreaRef}
        onChange={handleTextChange}
        rows={rows}
      />
      <style jsx>
        {`
          textarea {
            min-height: ${minHeight};
            min-width: 100%;
            max-width: 100%;
            background: transparent;
            color: rgba(var(--purple-rgb), 0.9);
            font-size: larger;
            font-weight: 500;
            padding: 3px 5px 3px 5px;
            font-family: "Source Sans Pro", sans-serif;
            box-shadow: ${isError ? "0 0 5px 0 var(--danger-font-color)" : "0 0 4px 0 rgba(var(--purple-rgb), 0.8)"};
            outline-style: none;
            border: none;
            border-radius: var(--border-radius);
          }
          textarea::-webkit-scrollbar {
            display: none;
          }
          textarea:focus {
            box-shadow: 0 0 4px 0 rgba(var(--pink-rgb), 0.8);
          }
           {
            /* @media (max-width: 550px) {
            textarea {
              max-width: 363px;
            }
          } */
          }
        `}
      </style>
    </>
  );
};

export default React.memo(TextArea);
