import React, { useContext } from "react";
import colors from "../colors";
import { css } from "@emotion/css";
import { MdWorkspacePremium } from "react-icons/md";
import DefaultButton from "../common/DefaultButton";
import { useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { isProduction } from "../../config";
import { PlanType } from "../../api/dtos/PlanType";
import { AppContext } from "../AppContext";
import { LoadingState } from "../../common/LoadingState";

const BASE_URL = isProduction ? "https://lovepage.io" : "http://localhost:4000";

type Props = {
    onUpgradeClick: (planType: PlanType) => void;
    price: string;
    fraction: string;
    calendarText: string;
    isAuth: boolean;
    planType: PlanType;
}

export const Plan: React.FC<Props> = ({
  onUpgradeClick,
  price,
  fraction,
  calendarText,
  isAuth,
  planType,
}) => {
  const navigate = useNavigate();
  const { state } = useContext(AppContext);
  const isSale = localStorage.getItem("isSale") === "true" &&
    state.userDto.state === LoadingState.LOADED &&
    state.userDto.data.plan === PlanType.FREE &&
    new Date() < new Date("2025-03-14T00:00:00");

  const styles = css`
    width: 100%;
    display: flex;
    justify-content: center;
    .Premium-Plan {
        height: fit-content;
        width: 80%;
        min-width: 300px;
        max-width: 300px;
        max-height: 500px;
        box-shadow: 0px 0px 10px 0px ${colors.gray};
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-right: 100%;
        animation: slideIn 1s ease-in-out;
        animation-fill-mode: forwards;
        @keyframes slideIn {
          0% {
            margin-right: 100%;
            opacity: 0;
          }
          100% {
            margin-right: 0%;
            opacity: 1;
          }
        }
      }
    .Premium-Plan-Content {
        border: 1px solid ${colors.gray};
        border-top: none;
        border-radius: 5px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        display: flex;
        align-items: center;
        flex-direction: column;
        height: fit-content;
        width: 100%;
        box-sizing: border-box;
        padding-bottom: 2em;
    }
    .Premium-Plan-Title {
        display: flex;
        justify-content: center;
    }
    .Premium-Plan-Price {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: sans-serif;
    }
    .Price-First, .Price-Second {
        font-weight: bold;
    }
    .Price-First {
        font-size: 2.3em;
    }
    .Price-Second {
        font-size: 1.5em;
    }
    .Price-Sign {
        font-size: 2em;
    }
    .Calendar-Text, .Premium-Plan-Notice {
        display: flex;
        justify-content: center;
        padding: 5px;
    }
    .Premium-Plan-Notice {
        margin-top: 25px;
        color: ${colors.grayDark2};
    }
    .Premium-Plan-Features {
        border-top: 1px solid ${colors.gray};
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 1em 0;
        margin-top: 1em;
        width: 70%;
        box-sizing: border-box;
    }
    .Premium-Plan-Feature {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        gap: 8px;
    }
    .Premium-Plan-Banner {
        background-color: ${colors.premiumPurple};
        display: flex;
        justify-content: center;
        padding: 5px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    .UpgradeButton-Container {
        width: 200px;
    }
    .Premium-Plan-Content > s {
        text-decoration: line-through;
        text-decoration-thickness: 2px;
        margin-top: -10px;
        margin-bottom: 5px;
    }
    `

  const buttonText = isAuth ? "Upgrade" : "Sign Up";

  if ( !isAuth ) {
    localStorage.setItem("selectedPremium", "true");
    onUpgradeClick = () => {
      navigate("/sign-in")
    }
  }
  return <div className={styles}>
    <div className="Premium-Plan">
      <div className="Premium-Plan-Banner">
        <MdWorkspacePremium color={colors.white2} size={25} />
      </div>
      <div className="Premium-Plan-Content">
        <div className="Premium-Plan-Title">
          <h1>{planType == PlanType.ANNUAL ? "Annual" : "Lifetime"} Premium</h1>
        </div>
        {isSale && (
          <div className="Premium-Plan-Discount">
            <s><b>$ {planType == PlanType.ANNUAL ? "10.00" : "25.00"}</b></s>
          </div>
        )}
        <div className="Premium-Plan-Price">
          <small className="Price-Sign">$ </small>
          {isSale && <>
            <span className="Price-First">
              {planType == PlanType.ANNUAL ? "4." : "12."}
            </span>
            <sup className="Price-Second">
              {planType == PlanType.ANNUAL ? "99" : "49"}
            </sup>
          </>}
          {!isSale && <>
            <span className="Price-First">{price}</span>
            <sup className="Price-Second">{fraction}</sup>
          </>}
          &nbsp;
        </div>
        <div className="Calendar-Text">{calendarText}</div>
        <div className="Premium-Plan-Notice">
          No setup costs
        </div>
        <div style={{ marginTop: "8px" }} className="Premium-Plan-Notice">
          No hidden fees
        </div>
        <div className="Premium-Plan-Features">
          <div className="Premium-Plan-Feature">
            <FaCheck size={20} />
          Premium Templates
          </div>
          <div className="Premium-Plan-Feature">
            <FaCheck size={20} />
          Unlimited websites
          </div>
        </div>
        {isAuth && <DefaultButton className="Upgrade-Button"
          onClick={() => onUpgradeClick(planType)}
          text={buttonText} color="purple" size="medium" />
        }
        {!isAuth && <div className="UpgradeButton-Container">
          <DefaultButton className="Upgrade-Button"
            onClick={() => onUpgradeClick(planType)}
            text={buttonText} color="purple" size="medium" />
        </div>
        }
      </div>
    </div>
  </div>
}
