import React from "react";
import Dialog from "../dialog/Dialog";
import { DisablePrivacyDialogFooter } from "./DisablePrivacyDialogFooter";
import { IoShieldCheckmark } from "react-icons/io5";
import colors from "../colors";

type Props = {
  isConfirmButtonSpin: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const DisablePrivacyDialog: React.FC<Props> = ({
  isConfirmButtonSpin,
  onConfirm,
  onCancel,
}) => {
  return (
    <Dialog
      footer={
        <DisablePrivacyDialogFooter
          isConfirmButtonSpin={isConfirmButtonSpin}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      }
      onClose={() => !isConfirmButtonSpin && onCancel()}
      isCloseDisabled={isConfirmButtonSpin}
    >
      <div className="disable-privacy-content">
        <div className="disable-privacy-icon">
          <IoShieldCheckmark size={48} color={colors.pinkRed} />
        </div>
        <h2>Are You Sure You Want to Remove Protection?</h2>
        <p className="disable-privacy-description">
          This will make your love story accessible to everyone. Your intimate moments and personal memories will no longer be protected by a password.
        </p>
      </div>
      <style>
        {`
          .disable-privacy-content {
            text-align: center;
            padding: 1rem;
          }

          .disable-privacy-icon {
            margin-bottom: 1.5rem;
          }

          .disable-privacy-content h2 {
            color: ${colors.white2};
            margin-bottom: 1rem;
            font-size: 1.5rem;
            font-weight: 600;
            text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          }

          .disable-privacy-description {
            color: ${colors.white2};
            margin-bottom: 1.5rem;
            font-size: 1.1rem;
            line-height: 1.4;
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
          }
        `}
      </style>
    </Dialog>
  );
}; 