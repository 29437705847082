import React, { useState } from "react";
import { FaLock } from "react-icons/fa";
import DefaultButton from "./DefaultButton";
import colors from "../colors";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../ErrorMessage";

interface Props {
  onSubmit: (password: string) => void;
  isSpin?: boolean;
  error?: string;
  title?: string;
  description?: string;
}

const PasswordInputDialog: React.FC<Props> = ({ onSubmit, isSpin = false, error, title, description }) => {
  const [password, setPassword] = useState("");
  const { t } = useTranslation();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(password);
  };

  return (
    <div className="password-dialog-overlay">
      <div className="password-dialog-content">
        <div className="password-dialog-icon">
          <FaLock size={48} color={colors.white2} />
        </div>
        <h2>{title || t("This page is password protected")}</h2>
        <form onSubmit={handleSubmit} className="password-form">
          <div className="PasswordInputDialog-Description">
            {description || t("Please enter the password to continue.")}
          </div>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={t("Enter password")}
            disabled={isSpin}
            className="password-input"
          />
          {error && <ErrorMessage message={error} />}
          <div className="password-button-container">
            <DefaultButton
              text={t("Submit")}
              onClick={handleSubmit}
              isSpin={isSpin}
              disabled={!password || isSpin}
              color="gradient"
            />
          </div>
        </form>
      </div>
      <style>
        {`
          .password-dialog-overlay {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.7);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
          }

          .password-dialog-content {
            background: linear-gradient(135deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7));
            padding: 2rem;
            border-radius: 15px;
            width: 90%;
            max-width: 400px;
            text-align: center;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          }

          .password-dialog-icon {
            margin-bottom: 1.5rem;
          }

          .password-dialog-content h2 {
            color: ${colors.white2};
            margin-bottom: 1.5rem;
            font-size: 1.5rem;
          }

          .password-form {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
          }

          .password-input {
            width: 100%;
            padding: 0.8rem;
            border: 2px solid ${colors.white2};
            border-radius: 8px;
            background: rgba(255, 255, 255, 0.1);
            color: ${colors.white2};
            font-size: 1rem;
            text-align: center;
            outline: none;
            transition: all 0.3s ease;
          }

          .password-input:focus {
            border-color: ${colors.premiumPurple};
            background: rgba(255, 255, 255, 0.15);
          }

          .password-input::placeholder {
            color: rgba(255, 255, 255, 0.5);
          }

          .password-error {
            color: #ff6b6b;
            font-size: 0.9rem;
            margin-top: 0.5rem;
          }

          .password-button-container {
            width: 100%;
            max-width: 200px;
            margin-top: 1rem;
          }

          .PasswordInputDialog-Description {
            font-size: 1.1em;
            color: ${colors.white2};
            text-align: center;
            padding: 0 1em;
          }
        `}
      </style>
    </div>
  );
};

export default PasswordInputDialog; 