import { css } from "@emotion/css";
import React from "react";
import { useTranslation } from "react-i18next";
import LoveBox from "../LoveBox/LoveBox";

export const FeatureColumns = () => {
  const { t } = useTranslation();
  const featuresStyle = css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4.5em;
    justify-content: flex-start;
    width: 100%;
    padding: 5em 2em;
    box-sizing: border-box;
    .Feature-Container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      gap: 1em;
      padding: 1rem;
      box-shadow: inset 0 0 20px 0px white, inset 0 0 20px 20px white;
      background: linear-gradient(135deg, #f97f85, #7f7ffe);
    }
    .Feature-Body {
      background-color: white;
      border-radius: 5px;
      height: 100%;
      max-width: 100%;
      padding: 18px;
    }
    .Feature-Icon {
      display: flex;
    }
    .easy-to-use-img {
      width: 250px;
      height: 150px;
    }
    .no-coding-img {
      width: 200px;
      height: 200px;
    }
    .special-img {
      width: 180px;
      height: 200px;
    }
    .Feature-Header {
      font-size: 1.5em;
      margin: 0;
      font-weight: bold;
    }
    .Feature-Text {
      font-size: 1em;
      margin: 0;
      font-family: "Source Sans Pro", sans-serif;
      color: #777777;
      line-height: 1.5em;
    }
  `;

  return (
    <div className={featuresStyle}>
      <LoveBox>
        <div className="Feature-Icon">
          <img className="easy-to-use-img" src="https://ik.imagekit.io/ex5o5cwj51/easy-to-use-4.png?updatedAt=1687388640272" alt="Easy to use" />
        </div>
        <h4 className="Feature-Header">{t("easy-to-use")}</h4>
        <p className="Feature-Text">{t("easy-to-use-sub")}</p>
      </LoveBox>

      <LoveBox>
        <div className="Feature-Icon">
          <img
            className="no-coding-img"
            src="https://ik.imagekit.io/ex5o5cwj51/no-coding-2.png?updatedAt=1687389700970"
            alt="no coding skills required"
          />
        </div>
        <h4 className="Feature-Header">{t("no-coding-skills-required")}</h4>
        <p className="Feature-Text">{t("no-coding-skills-required-sub")}</p>
      </LoveBox>
      <LoveBox>
        <div className="Feature-Icon">
          <img
            className="special-img"
            src="https://ik.imagekit.io/ex5o5cwj51/surprise-3.png?updatedAt=1687420554129"
            alt="special, unforgettable originality"
          />
        </div>
        <h4 className="Feature-Header">{t("unforgettable-originality")}</h4>
        <p className="Feature-Text">{t("unforgettable-originality-sub")}</p>
      </LoveBox>
    </div>
  );
};
