import React from "react";
import colors from "../../colors";
import styles from "./styles.module.css";

interface Props {
  isEnabled: boolean;
  onChange: (enabled: boolean) => void;
  disabled?: boolean;
  icon?: React.ReactNode;
  text?: string;
}

const ToggleSwitch: React.FC<Props> = ({ isEnabled, onChange, disabled = false, icon, text = "" }) => {
  return (
    <button
      className={`${styles.toggleSwitch} ${isEnabled ? styles.enabled : ""} ${disabled ? styles.disabled : ""}`}
      onClick={() => !disabled && onChange(!isEnabled)}
      disabled={disabled}
      type="button"
      role="switch"
      aria-checked={isEnabled}
    >
      <div className={styles.toggleTrack}>
        <div className={styles.toggleThumb}>{icon}</div> {text}
      </div>
    </button>
  );
};

export default ToggleSwitch;
