import React, { ReactNode } from "react";
import styles from "./styles.module.css";

type Props = {
  children: ReactNode;
};

const LoveBox = ({ children }: Props) => {
  return (
    <div className={styles.boxContainer}>
      <div className={styles.boxBody}>{children}</div>
    </div>
  );
};

export default LoveBox;
