import React, { ChangeEvent, useState } from "react";
import Input from "../common/Input";
import DefaultButton from "../common/DefaultButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import ErrorMessage from "../ErrorMessage";
import colors from "../colors";
import Header from "../header/Header";
import { sendSubscribeEmail } from "./SubscribeApi";
import { css } from "@emotion/css";
import { validateEmail } from "../auth/Validator.util";

const Subscribe: React.FC = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSendBtnSpin, setSendBtnSpin] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isEmailSent = !!searchParams.has("confirmation");

  function handleEmailChange(val: ChangeEvent<HTMLInputElement>) {
    setErrorMessage("");
    setEmail(val.target.value);
  }

  async function handleSendClick() {
    try {
      const result = validateEmail(email);
      if (result) {
        setErrorMessage(result);
        return;
      }
      setSendBtnSpin(true);
      await sendSubscribeEmail(email);
      navigate("/subscribe?confirmation");
    } catch (error) {
      if (error?.response?.status === 422) {
        setErrorMessage("This is not a valid email address");
      }
    } finally {
      setSendBtnSpin(false);
    }
  }

  if (isEmailSent) {
    return (
      <div className="Subscribe">
        <Header />
        <h1>Check your email</h1>
        <p>You have successfully subscribed to our newsletter.</p>
        <style>
          {`
            .Subscribe {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                color: ${colors.white2}
            }
        `}
        </style>
      </div>
    );
  }

  const labelTextCss = css`
    color: ${colors.white2};
    font-size: 1.2em;
    margin-bottom: 0.5em;
    display: flex;
    flex-direction: column;
  `;

  return (
    <div className="Subscribe-Page">
      <Header />
      <div className="Subscribe">
        <h3 className="Subscribe-Header">Subscribe to our newsletter</h3>
        <p>You will receive occasional updates and offers about our products and services. to your email address.</p>
        <div className="Subscribe-Form">
          {!!errorMessage && <ErrorMessage message={errorMessage} />}
          <label>
            <span className={labelTextCss}>Type your email address:</span>
            <Input isError={!!errorMessage} defaultValue="" onChange={handleEmailChange} textAlign="left" placeholder="Email Address" />
          </label>
          <div className="Subscribe-Form-Button">
            <DefaultButton isSpin={isSendBtnSpin} color="green" size="medium" text="Subscribe" onClick={handleSendClick} />
          </div>
        </div>
        <style>
          {`
            .Subscribe-Page {
              height: 100%;
            }
            .Subscribe {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                text-align: left;
                gap: 1em;
                width: 100%;
                color: ${colors.white2};
                min-height: 70vh;
                padding-top: 2em;
            }
            .Subscribe-Header {
                font-size: 1.5em;
                margin-bottom: 0;
            }   
            .Subscribe-Form {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 1em;
                padding: 1em;
            }
            .Subscribe-Form-Button {
                width: 100%;
                max-width: 200px;
            }
            .Subscribe > p {
                padding: 0 1em;
                max-width: 400px;
            }
        `}
        </style>
      </div>
    </div>
  );
};

export default Subscribe;
