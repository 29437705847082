const quotesText = "#ffadad";
const red = "#ff375f";
const pinkRed = "#ff4d7e";
const green = "#2d793f";
const linkBlue = "#64D2FF";
const gray = "#b9b9c1";
const grayDark = "#636366";
const grayDark2 = "#353535";
const white = "#FFFFFF";
const white2 = "#F2F2F7";
const purple = "#8d5b9f";
const blue = "rgba(35, 35, 223, 1)";
const lightBlue = "#5daafd";
const premiumPurple = "#6e299d";
const purpleBlueGradient =
 "linear-gradient(to right, #89253e, #3a6186);";
const black = "#000000";
const black2 = "#353535";
export default {
  quotesText,
  red,
  pinkRed,
  green,
  linkBlue,
  white2,
  grayDark,
  grayDark2,
  gray,
  white,
  purple,
  blue,
  lightBlue,
  premiumPurple,
  purpleBlueGradient,
  black,
  black2,
};
