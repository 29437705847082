import React, { useState } from "react";
import DefaultButton from "../common/DefaultButton";
import colors from "../colors";
import { useTranslation } from "react-i18next";
import { IoInformationCircleOutline } from "react-icons/io5";

type Props = {
  isSaveButtonSpin: boolean;
  onSave: (password: string) => void;
  onCancel: () => void;
}

export const SetPrivacyPasswordDialogFooter: React.FC<Props> = ({
  isSaveButtonSpin,
  onSave,
  onCancel,
}) => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { t } = useTranslation();

  const handleSave = () => {
    if (password.length < 6) {
      setError("❌ Password must be at least 6 characters long");
      return;
    }
    onSave(password);
  };

  return <div className="SetPrivacyPasswordDialogFooter">
    <div className="SetPrivacyPasswordDialogFooter-Input">
      <input
        type="text"
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
          setError("");
        }}
        maxLength={25}
        placeholder="Enter password"
        className="SetPrivacyPasswordDialogFooter-PasswordInput"
      />
      {error && <div className="SetPrivacyPasswordDialogFooter-Error">{error}</div>}
    </div>
    <div className="SetPrivacyPasswordDialogFooter-Buttons">
      <div className="SetPrivacyPasswordDialogFooter-Item">
        <DefaultButton
          isSpin={isSaveButtonSpin}
          onClick={handleSave}
          size="medium"
          text="Save" />
      </div>
      <div className="SetPrivacyPasswordDialogFooter-Item">
        <DefaultButton
          disabled={isSaveButtonSpin}
          onClick={onCancel}
          size="medium"
          text="Cancel" />
      </div>
    </div>
    <div className="SetPrivacyPasswordDialogFooter-Info">
      <IoInformationCircleOutline size={20} className="SetPrivacyPasswordDialogFooter-Info-Icon" />
      <span>{t("Your love page will be only accessible by password when privacy mode is enabled, you can disable it anytime")}</span>
    </div>
    <style>
      {`
        .SetPrivacyPasswordDialogFooter {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          gap: 1em;
          padding: 1em;
          box-sizing: border-box;
        }
        .SetPrivacyPasswordDialogFooter-Input {
          width: 80%;
          max-width: 300px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 0.5em;
        }
        .SetPrivacyPasswordDialogFooter-PasswordInput {
          width: 100%;
          padding: 0.8em;
          border-radius: 5px;
          border: 1px solid ${colors.white2};
          background: transparent;
          color: ${colors.white2};
          font-size: 1.2em;
          text-align: center;
        }
        .SetPrivacyPasswordDialogFooter-PasswordInput::placeholder {
          color: ${colors.white2};
          opacity: 0.7;
        }
        .SetPrivacyPasswordDialogFooter-PasswordInput:focus {
          outline: none;
          border-color: ${colors.lightBlue};
        }
        .SetPrivacyPasswordDialogFooter-Error {
          color: #ff6b6b;
          font-size: 1em;
        }
        .SetPrivacyPasswordDialogFooter-Buttons {
          display: flex;
          justify-content: space-around;
          align-items: center;
          flex-direction: row;
          width: 100%;
          gap: 1em;
          height: 60px;
          font-weight: bold;
        }
        .SetPrivacyPasswordDialogFooter-Item {
          width: 10em;
        }
        .SetPrivacyPasswordDialogFooter-Info {
          font-size: 0.9em;
          color: ${colors.white2};
          opacity: 0.8;
          text-align: center;
          padding: 0 1em;
          line-height: 1.4;
          max-width: 400px;
          display: flex;
          align-items: center;
          gap: 0.5em;
        }
        .SetPrivacyPasswordDialogFooter-Info-Icon {
          color: ${colors.white2};
          opacity: 0.8;
          flex-shrink: 0;
        }
      `}
    </style>
  </div>
} 