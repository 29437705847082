import React from "react";
import colors from "../colors";
import { Footer } from "../Footer/Footer";
import LogoHeader from "../header/LogoHeader";
import Header from "../header/Header";

const SignInLinkSent: React.FC = () => {
  return (
    <>
      <Header />
      <div className="SignInLinkSent">
        <h4 className="success-header">
          Sign in link was sent to your email address <span>✉️</span>
        </h4>
        <p className="not-received-info">
          Not received the email?
          <br /> Check your spam folder or <a href="/sign-in">try again</a>.
        </p>
        <style jsx>
          {`
            .SignInLinkSent {
              display: flex;
              flex-direction: column;
              justify-content: start;
              align-items: center;
              gap: 1em;
              height: 80vh;
              padding-bottom: 5em;
            }
            .success-header {
              margin-top: 2em;
              color: ${colors.white2};
              font-size: 1.8em;
              font-weight: 600;
              background: -webkit-linear-gradient(45deg, #827ffc, #fb7f82 80%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            .success-header > span {
              background: white;
              -webkit-background-clip: unset;
              -webkit-text-fill-color: white;
            }
            .not-received-info {
              color: ${colors.white2};
              text-align: center;
              background: -webkit-linear-gradient(45deg, #827ffc, #fb7f82 80%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            .not-received-info a {
              color: ${colors.white2};
              text-decoration: underline;
              background: none;
              -webkit-background-clip: unset;
              -webkit-text-fill-color: #ab5ffd;
              font-weight: bolder;
            }

            @media only screen and (max-width: 600px) {
              .success-header {
                font-size: 1.4em;
                text-align: center;
              }
            }
          `}
        </style>
      </div>
      <Footer />
    </>
  );
};

export default SignInLinkSent;
