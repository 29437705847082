import React, { useEffect } from "react";
import colors from "../../colors";
import PhotoView from "../PhotoView";
import { CardViewModal } from "./CardViewModal";
import { ViewPageState } from "../state/ViewPageState";
import { PageItemType } from "../../edit/dto/PageItemType";
import { css } from "@emotion/css";
import QuoteView from "../QuoteView";
import ExpandButton from "../../common/ExpandButton";
import { UpgradeBanner } from "../UpgradeBanner";

type Props = {
  page: ViewPageState;
  isAuthenticated: boolean;
};

export const AnniversaryPage: React.FC<Props> = ({ page, isAuthenticated }) => {
  const [selectedCollageItem, setSelectedCollageItem] = React.useState<number | null>(null);
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [blur, setBlur] = React.useState(!isAuthenticated);

  React.useEffect(() => {
    if (isAuthenticated) {
      const timer = setTimeout(() => {
        setBlur(true);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    !localStorage.getItem("hasSeenModalBefore") && setShowTooltip(true);
  }, []);

  const anniversaryTextStyles = css`
    font-family: "Alex Brush", sans-serif;
    font-size: 4em;
    color: ${colors.white2};
    text-align: center;
    border: none;
    padding: 5px;
    margin: 0;
    margin-top: 5px;
    line-height: 1.2;
    width: 100%;
    max-width: 600px;
    box-sizing: border-box;
  `;

  const calendarTextStyle = css`
    color: ${colors.grayDark2};
    padding: 5px;
    margin: 0;
    border: none;
    &:focus {
      border: 1px dashed transparent;
    }
    font-weight: bold;
    font-family: "Lora Variable", sans-serif;
    font-size: 16px;
    line-height: 24px;
    word-break: break-word;
    position: relative;
    width: 100%;
    max-width: 600px;
    line-height: 1;
    -webkit-text-stroke: 3px #fff; /* for Chrome, Safari, and Opera */
    text-stroke: 3px #fff; /* standard syntax */
    text-shadow: 0px 0px 0px rgb(0, 0, 0, 0.1);
    background-clip: text;
    width: fit-content;
    color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    text-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.7);
    font-family: "Lora Variable", sans-serif;
    font-size: 3.8em;
    font-weight: bold;
    box-sizing: border-box;
    @media (min-width: 768px) {
      font-size: 5em;
    }
  `;

  const expandBtnStyle = css`
    position: absolute;
    top: 0;
    left: 0;
  `;

  function handleViewModalClose() {
    setSelectedCollageItem(null);
  }

  function handleExpandClick(index: number) {
    setShowTooltip(false);
    localStorage.setItem("hasSeenModalBefore", "true");
    setSelectedCollageItem(index);
  }

  const tooltipStyles = css`
    display: flex;
    position: absolute;
    width: 100px;
    height: fit-content;
    top: 45px;
    left: 2px;
    width: 150px;
    height: 70px;
    padding: 10px;
    font-size: 20px;
    word-break: none;
    z-index: 8;
    background-color: ${colors.grayDark2};
    color: ${colors.white2};
    position: absolute;
    cursor: pointer;
    &::after {
      opacity: 0;
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-width: 12px;
      border-style: solid;
      border-color: transparent ${colors.grayDark2} transparent transparent;
      transform: rotate(90deg);
      left: 2px;
      top: -24px;
      animation: showit 0.5s;
      @keyframes showit {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      animation-fill-mode: forwards;
    }
    animation: movebtns 0.5s;
    @keyframes movebtns {
      0% {
        margin-bottom: 0em;
        opacity: 0;
      }
      100% {
        margin-bottom: 1em;
        opacity: 1;
      }
    }
    animation-fill-mode: forwards;
  `;

  return (
    <div className="AnniversaryTemplate">
      {!page.hasActivePlan && <UpgradeBanner isExpired={page.isExpired} />}
      {blur && page.isExpired && <div className="Expired-Blur" />}
      <div className="AnniversaryTemplate-Collage">
        {page.items
          .filter((p) => p.type === PageItemType.PHOTO)
          .filter((item, i) => i < 4)
          .map((item, index) => {
            return (
              <div key={item.key} onClick={() => handleExpandClick(index)} className="AnniversaryTemplate-CollageItem">
                <PhotoView size="half" image={item.data} />
                {index === 0 && (
                  <div className={expandBtnStyle} onClick={() => handleExpandClick(index)}>
                    <ExpandButton size="small" onClick={() => handleExpandClick(index)} />
                  </div>
                )}
              </div>
            );
          })}
        {showTooltip && (
          <div onClick={() => handleExpandClick(0)} className={tooltipStyles}>
            Click to the expand button to see more..
          </div>
        )}
        {selectedCollageItem !== null && <CardViewModal state={page} selectedItemIndex={selectedCollageItem} onClose={handleViewModalClose} />}
      </div>
      <div className="AnniversaryTemplate-Bottom-Text">
        <h1 className={calendarTextStyle}>{page.items[12].data}</h1>
        <p className={anniversaryTextStyles}>{page.items[13].data}</p>
      </div>
      <div className="AnniversaryTemplate-Items">
        {page.items
          .filter((item, i) => i > 13)
          .map((item, index) => {
            if (item.type === PageItemType.PHOTO) {
              return <PhotoView size="full" image={item.data} />;
            } else {
              return <QuoteView text={item.data} />;
            }
          })}
      </div>
      <style>
        {`
      .Expired-Blur {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
        background-color: transparent;
        backdrop-filter: blur(5px);
      }
      .AnniversaryTemplate {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 0;
        padding-top: 2em;
      }

      .AnniversaryTemplate-Collage {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        padding: 2px;
        box-sizing: border-box;
        gap: 3px;
        position: relative;
        box-sizing: border-box;
      }

      .AnniversaryTemplate-CollageItem {
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
        position: relative;
        border: none;
        padding: 0px;
        box-sizing: border-box;
        height: 20vh;
        cursor: pointer;
      }

      .AnniversaryTemplate-Text {
        font-family: 'Alex Brush', sans-serif;
        font-size: 10vw;
        color: ${colors.white2};
        text-align: center;
      }
      .AnniversaryTemplate-Bottom-Text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
      }

      .AnniversaryTemplate-Items {
        padding-top: 3em;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 3em;
        width: 100%;
        max-width: 540px;
      }


      @media (min-width: 768px) {
        .AnniversaryTemplate {
          width: 70%;
        }
      }

      .AnniversaryTemplate-Collage {
        max-width: 600px;
      }
    `}
      </style>
    </div>
  );
};
