import React from "react";
import DefaultButton from "../common/DefaultButton";

type Props = {
  isConfirmButtonSpin: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const DisablePrivacyDialogFooter: React.FC<Props> = ({
  isConfirmButtonSpin,
  onConfirm,
  onCancel,
}) => {
  return <div className="DisablePrivacyDialogFooter">
    <div className="DisablePrivacyDialogFooter-Buttons">
      <div className="DisablePrivacyDialogFooter-Item">
        <DefaultButton
          isSpin={isConfirmButtonSpin}
          onClick={onConfirm}
          size="medium"
          text="Disable" />
      </div>
      <div className="DisablePrivacyDialogFooter-Item">
        <DefaultButton
          disabled={isConfirmButtonSpin}
          onClick={onCancel}
          size="medium"
          text="Cancel" />
      </div>
    </div>
    <style>
      {`
        .DisablePrivacyDialogFooter {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          gap: 1em;
          padding: 1em;
          box-sizing: border-box;
        }
        .DisablePrivacyDialogFooter-Buttons {
          display: flex;
          justify-content: space-around;
          align-items: center;
          flex-direction: row;
          width: 100%;
          gap: 1em;
          height: 60px;
          font-weight: bold;
        }
        .DisablePrivacyDialogFooter-Item {
          width: 10em;
        }
      `}
    </style>
  </div>
} 