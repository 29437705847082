import React, { useState } from "react";
import { MdIosShare, MdOutlineContentCopy } from "react-icons/md";
import { IoQrCodeOutline } from "react-icons/io5";
import colors from "../colors";
import { usePageUrl } from "./usePageUrl";
import { FaCaretDown } from "react-icons/fa";
import DefaultButton from "../common/DefaultButton";
import { RiDeleteBinLine } from "react-icons/ri";
import { Toast } from "../common/Toast";

type Props = {
    pageId: number;
    className: string;
    onDeleteClick: () => void;
    onQrCodeClick: () => void;
}
export const WebPageItemDropdown: React.FC<Props> = ({ pageId, className,
  onDeleteClick, onQrCodeClick }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const pageUrl = usePageUrl(pageId)

  const shareData = {
    title: "See my Love Page",
    url: pageUrl,
  }

  function isShareAvailable() {
    return navigator.share !== undefined &&
    navigator.canShare(shareData);
  }


  async function handleShareClick() {
    if (isShareAvailable()) {
      await navigator.share(shareData);
    } else {
      await navigator?.clipboard?.writeText?.(pageUrl);
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 2000);
    }
  }


  const moreButtonIcon = (<FaCaretDown color="black"
    className="EditButton" size="20"/>);

  return <div className={className}>
    <div className={`${className}-More-Button`}>
      <DefaultButton onBlur={() => setTimeout(() => {
        setShowDropdown(false)
      }, 200)}
      className="WebPageItem-More-Btn"
      iconPosition="right"
      icon={moreButtonIcon} text="More" size="medium"
      onClick={() => setShowDropdown((v) => !v)} />
    </div>
    <div className={`${className}-Content`}>
      <div className={`${className}-Item`}>
        <button className={`${className}-Item-Button`}
          onClick={onQrCodeClick}>
          <IoQrCodeOutline className="Dropdown-Icon" size={40}/>
          <span>
            Generate QR Code
          </span>
        </button>
      </div>
      <div className={`${className}-Item`}>
        <button className={`${className}-Item-Button`}
          onClick={handleShareClick}>

          {isShareAvailable() ? <><MdIosShare className="Dropdown-Icon"
            size={30}/>
          <span>Share Page</span></> :
            <>
              <MdOutlineContentCopy className="Dropdown-Icon" size={30}/>
              <span>Copy Link</span>
            </>
          }
        </button>
      </div>
      <div className={`${className}-Item`}>
        <button className={`${className}-Item-Button`}
          onClick={onDeleteClick}>

          <RiDeleteBinLine className="Dropdown-Icon" size={30}/>
          <span>Delete Page</span>
        </button>
      </div>
    </div>
    { showToast && <Toast>Copied </Toast> }
    <style>
      {`
        .${className} {
            background-color: rgb(242, 242, 247, 0);
            position: relative;
            height: 100%;
        }
        .${className}-More-Button {
            height: 100%;
        }

        .${className}-Content {
            position: absolute;
            display: flex;
            flex-direction: column;
            opacity: ${showDropdown ? 1 : 0};
            border-radius: 5px;
            padding: 2px;
            background-color: black;
            margin-top: 1px;
            box-shadow: 0 0 1px ${colors.grayDark};
            width: 8em;
            z-index: ${showDropdown ? 1 : -2};
        }

        .${className}-Item {
            width: 100%;
        }
        .${className}-Item-Button:hover {
            background-color: rgb(220,220,220,1);
        }

        .${className}-Item-Button > span {
            padding-left: 1em;
            word-break: initial;
            color: black;
            font-weight: 500;
            width: 100%;
            text-align: left;
        }

        .${className}-Item-Button {
            width: 100%;
            cursor: pointer;
            padding: 3px;
            border: none;
            border-bottom: 1px solid ${colors.grayDark};
            display: flex;
            align-items: center;
        }
        .Dropdown-Icon {
            color: black;
        }
    `}
    </style>
  </div>;
};
