import { action, makeAutoObservable, observable } from "mobx";
import { TemplateName } from "../../../shared/Enums/TemplateName";
import { PageItem } from "../../edit/dto/PageItem";

export class ViewPageState {
  items: PageItem[] = [];
  templateName: TemplateName = null;
  isExpired: boolean = false;
  hasActivePlan: boolean = false;
  isPrivacyModeEnabled: boolean = false;
  enabledPages: number[] = [];

  constructor() {
    makeAutoObservable(this, {
      items: observable,
      templateName: observable,
      isExpired: observable,
      hasActivePlan: observable,
      setTemplateName: action,
      setItems: action,
      setIsExpired: action,
      setHasActivePlan: action,
      setIsPrivacyModeEnabled: action,
    });
  }

  setTemplateName(templateName: TemplateName) {
    this.templateName = templateName;
  }

  setItems(items: PageItem[]) {
    this.items = items;
  }

  setIsExpired(isExpired: boolean) {
    this.isExpired = isExpired;
  }

  setHasActivePlan(hasActivePlan: boolean) {
    this.hasActivePlan = hasActivePlan;
  }

  setIsPrivacyModeEnabled(isPrivacyModeEnabled: boolean) {
    this.isPrivacyModeEnabled = isPrivacyModeEnabled;
  }

  setEnabledPages(enabledPages?: number[]) {
    this.enabledPages = enabledPages;
  }
}
