import React, { useEffect } from "react";
import { ViewPageState } from "../../state/ViewPageState";
import { UpgradeBanner } from "../../UpgradeBanner";
import styles from "./styles.module.css";
import PhotoView from "../../PhotoView";

type Props = {
  page: ViewPageState;
  isAuthenticated: boolean;
};

const TheLoveTimesPage = ({ page, isAuthenticated }: Props) => {
  const [selectedCollageItem, setSelectedCollageItem] = React.useState<number | null>(null);
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [blur, setBlur] = React.useState(!isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      const timer = setTimeout(() => {
        setBlur(true);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    !localStorage.getItem("hasSeenModalBefore") && setShowTooltip(true);
  }, []);
  return (
    <>
      {!page.hasActivePlan && <UpgradeBanner isExpired={page.isExpired} />}
      <div className={`${styles.templateContainer} ${blur && page.isExpired ? styles.expiredBlur : ""}`}>
        {/* First Page */}
        <div className={styles.firstPageContainer}>
          <div className={styles.dividerWithText}>
            <div>{page.items[0].data}</div>

            <span>EDITION</span>
          </div>
          <div className={styles.newspaperTitleSection}>
            <div className={styles.subtitle}>
              <p>"ALL THE LOVE</p> <p>THAT'S FIT TO PRINT"</p>
            </div>
            <div className={styles.title}>THE LOVE TIMES</div>
          </div>
          <div className={styles.doubleDivider} />
          <div className={styles.issueSection}>
            <div className={styles.vol}>VOL.4</div>
            <div className={styles.releaseDate}>{page.items[1].data}</div>

            <div children={page.items[2].data} />
          </div>
          <div className={styles.divider} />
          <div className={styles.captionSection}>
            <div className={styles.caption} children={page.items[3].data} />
            <div className={styles.subcaption} children={page.items[4].data} />
          </div>
          <div className={styles.doubleDivider} />
          <div className={styles.headlineImage}>
            <PhotoView size="full" image={page.items[5].data} />
          </div>
          <div className={styles.doubleDivider} />
        </div>
        {/* End of First Page */}

        <br />

        {/* Second Page */}
        {/* <div className={styles.disableButton} onClick={() => togglePageDisabled(1)}>
        {!state.enabledPages.includes(1) ? "Enable this page" : "Disable this page"}
      </div> */}
        {page.enabledPages && page.enabledPages.includes(1) ? (
          <div className={`${styles.secondPageContainer}`}>
            <div className={styles.divider} />
            <div className={styles.newspaperTitleSection}>
              <div className={styles.title}>THE LOVE TIMES</div>
            </div>
            <div className={styles.doubleDivider} />
            <div className={styles.bucketListSection}>
              <div className={styles.bucketListImageContainer}>
                <div className={styles.bucketListImage}>
                  <PhotoView size="full" image={page.items[6].data} />
                </div>
                <div className={styles.bucketListImage} style={{ userSelect: "none" }}>
                  <PhotoView size="full" image={page.items[7].data} />
                </div>
              </div>
              <div className={styles.bucketListContainer}>
                <div className={styles.bucketListTitle}>Our Bucket List</div>
                <div className={styles.bucketListDivider}></div>
                <div className={styles.bucketList}>
                  <ul>
                    <li>
                      <div className={styles.iconDiv}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
                        </svg>
                      </div>
                      <div children={page.items[8].data} />
                    </li>

                    <li>
                      <div className={styles.iconDiv}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
                        </svg>
                      </div>
                      <div children={page.items[9].data} />
                    </li>
                    <li>
                      <div className={styles.iconDiv}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
                        </svg>
                      </div>
                      <div children={page.items[10].data} />
                    </li>
                    <li>
                      <div className={styles.iconDiv}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
                        </svg>
                      </div>
                      <div children={page.items[11].data} />
                    </li>
                    <li>
                      <div className={styles.iconDiv}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
                        </svg>
                      </div>
                      <div children={page.items[12].data} />
                    </li>
                    <li>
                      <div className={styles.iconDiv}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
                        </svg>
                      </div>
                      <div children={page.items[13].data} />
                    </li>
                    <li>
                      <div className={styles.iconDiv}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
                        </svg>
                      </div>
                      <div children={page.items[14].data} />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.divider} />
            <div className={styles.favoriteMemorySection}>
              <div className={styles.favoriteMemoryTextSection}>
                <div className={styles.favoriteMemoryTitle} children={page.items[15].data} />
                <div className={styles.favoriteMemoryText} children={page.items[16].data} />
              </div>
              <div className={styles.favoriteMemoryImageSection}>
                <div className={styles.favoriteMemoryImage}>
                  <PhotoView size="full" image={page.items[17].data} />
                </div>
              </div>
            </div>
            <div className={styles.doubleDivider} />
          </div>
        ) : null}

        <br />
        {/* Third Page */}
        {page.enabledPages && page.enabledPages.includes(2) ? (
          <div className={`${styles.thirdPageContainer} `}>
            <div className={styles.divider}></div>
            <div className={styles.newspaperTitleSection}>
              <div className={styles.title}>THE LOVE TIMES</div>
            </div>
            <div className={styles.doubleDivider} />
            <div className={styles.thirdPageHeadlineImage}>
              <PhotoView size="full" image={page.items[18].data} />
            </div>
            <div className={styles.divider} />
            <div className={styles.funFactSection}>
              <div className={styles.factsText}>LOVE FUN FACTS</div>
              <div className={styles.funFacts} children={page.items[19].data} />
              <div className={styles.funFacts} children={page.items[20].data} />
              <div className={styles.funFacts} children={page.items[21].data} />
            </div>
            <div className={styles.divider} />
            <div className={styles.favoriteThingsSection}>
              <div className={styles.favoriteThingsImage}>
                <PhotoView size="full" image={page.items[22].data} />
              </div>
              <div className={styles.favoriteThingsListSection}>
                <div className={styles.favoriteThingsTitle}>
                  <div children={page.items[23].data} />
                </div>
                <div className={styles.favoriteThingsList}>
                  <ul>
                    <li>
                      <div className={styles.iconDiv}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
                        </svg>
                      </div>
                      <div className={styles.favoriteThingsListEditable} children={page.items[24].data} />
                    </li>
                    <li>
                      <div className={styles.iconDiv}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
                        </svg>
                      </div>
                      <div className={styles.favoriteThingsListEditable} children={page.items[25].data} />
                    </li>
                    <li>
                      <div className={styles.iconDiv}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
                        </svg>
                      </div>
                      <div className={styles.favoriteThingsListEditable} children={page.items[26].data} />
                    </li>
                    <li>
                      <div className={styles.iconDiv}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
                        </svg>
                      </div>
                      <div className={styles.favoriteThingsListEditable} children={page.items[27].data} />
                    </li>
                    <li>
                      <div className={styles.iconDiv}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
                        </svg>
                      </div>
                      <div className={styles.favoriteThingsListEditable} children={page.items[28].data} />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.doubleDivider} />
          </div>
        ) : null}
        {/* End of Third Page */}
      </div>
    </>
  );
};

export default TheLoveTimesPage;
