import React, { useContext } from "react";
import EditPage from "./components/edit/EditPage";
import HomePage from "./components/homepage/HomePage";
import "./utils/BaseUrlLoader";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./components/dashboard/Dashboard";
import SignIn from "./components/auth/SignIn";
import Page from "./components/page/Page";
import { AppContext } from "./components/AppContext";
import useSubdomain from "./components/page/useSubdomain";
import PaymentResult from "./components/payment/PaymentResult";
import Account from "./components/account/Account";
import { LoadingState } from "./common/LoadingState";
import Support from "./components/help/Support";
import { ErrorPage } from "./components/errorPage/ErrorPage";
import { Terms } from "./components/terms/Terms";
import PasswordReset from "./components/passwordreset/PasswordReset";
import SetPassword from "./components/passwordreset/SetPassword";
import { ContactUs } from "./components/page/ContactUs";
import { Templates } from "./components/create/Templates";
import { EditPageState } from "./components/edit/state/EditPageState";
import { LoadingPage } from "./components/LoadingPage";
import "@fontsource/roboto";
import { UpgradeModal } from "./components/upgrade/UpgradeModal";
import Subscribe from "./components/subscribe/Subscribe";
import Unsubscribe from "./components/subscribe/Unsubscribe";
import SignInLink from "./components/auth/SignInLink";
import SignInLinkSent from "./components/auth/SignInLinkSent";
import CreateAccount from "./components/auth/CreateAccount";
import "./i18n";

const editPageState = new EditPageState();

const App = () => {
  const { state } = useContext(AppContext);
  const subdomain = useSubdomain();
  const hasSubdomain = !!subdomain && subdomain !== "www";
  const isAuthenticated = state.userDto.state === LoadingState.LOADED;
  const isLoading = state.userDto.state === LoadingState.IDLE || state.userDto.state === LoadingState.LOADING;

  const closeBtnClickPricing = () => {
    window.location.href = "/";
  };

  return (
    <BrowserRouter>
      <div className="App-Container">
        <div className="App-Component-Container">
          {hasSubdomain && (
            <Routes>
              <Route path="*" element={<Page subdomain={subdomain} />} />
            </Routes>
          )}
          {isLoading && (
            <Routes>
              <Route path="*" element={<LoadingPage />} />
            </Routes>
          )}
          {!isLoading && hasSubdomain === false && (
            <Routes>
              <Route path="/create" element={<EditPage editPageState={editPageState} />} />
              <Route path="/edit" element={<EditPage editPageState={editPageState} />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/templates" element={<Templates isAuth={isAuthenticated} />} />
              <Route path="/payment-result" element={<PaymentResult />} />
              <Route path="/account" element={<Account />} />
              <Route path="/support" element={<Support />} />
              <Route path="/subscribe" element={<Subscribe />} />
              <Route path="/unsubscribe" element={<Unsubscribe />} />
              <Route path="/error" element={<ErrorPage />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/pricing" element={<UpgradeModal onClose={closeBtnClickPricing} isAuth={false} />} />
              <Route path="/upgrade" element={<UpgradeModal isAuth={true} />} />
              <Route path="/terms-and-conditions" element={<Terms />} />
              <Route path="/password-reset" element={<PasswordReset />} />
              <Route path="/set-password" element={<SetPassword />} />
              {!isAuthenticated && (
                <>
                  <Route path="/sign-in" element={<SignIn />} />
                  <Route path="/sign-in-link" element={<SignInLink />} />
                  <Route path="/sign-in-link-sent" element={<SignInLinkSent />} />
                </>
              )}
              <Route path="/create-account" element={<CreateAccount />} />
              <Route path="/payment-result" element={<PaymentResult />} />
              <Route path="/*" element={<HomePage />} />
            </Routes>
          )}
        </div>
        <style jsx>
          {`
            .App-Container {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;
            }

            .App-Component-Container {
              width: 100vw;
              min-height: 100%;
              height: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-direction: column;
            }
          `}
        </style>
      </div>
    </BrowserRouter>
  );
};

export default App;
