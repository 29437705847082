import React, { FormEvent, useEffect, useState } from "react";
import styles from "./styles.module.css";
import { EditPageState } from "../../state/EditPageState";
import SelectImage from "../../SelectImage";
import { EditPageItem } from "../../EditPageItem";
import { PageItem } from "../../dto/PageItem";
import LoveEditableDiv from "../../LoveTextInput/LoveEditableDiv";
import { observer } from "mobx-react-lite";
import { getCreatePageDto } from "../../../create/CreatePageHelper";
import { TemplateName } from "../../../../shared/Enums/TemplateName";
import ToggleSwitch from "../../../common/ToggleSwitch/ToggleSwitch";
import { IoEye, IoEyeOff, IoLockClosed } from "react-icons/io5";

type Props = {
  state: EditPageState;
  isWarnEmptyFields: boolean;
};

const TheLoveTimesTemplate = observer(({ state, isWarnEmptyFields }: Props) => {
  const initialState = state;
  const renderImage = (index: number) => {
    return renderItem(state.items[index], index);
  };

  const renderItem = (item: PageItem, index: number) => {
    return (
      <EditPageItem
        data={item.data}
        type={item.type}
        index={index}
        isWarnEmpty={isWarnEmptyFields}
        key={item.key}
        onDragStart={() => null}
        onDragOver={() => null}
        onDrop={() => null}
        onDragEnd={() => null}
        onImageChange={(index, value) => handleImageChange(index, value)}
        onQuoteChange={() => null}
        onDeleteItem={() => null}
        onUpClick={() => null}
        onDownClick={() => null}
        focusedIndex={1}
        isDndTarget={() => null}
      />
    );
  };

  function handleImageChange(index: number, photo: string) {
    state.updateItem(index, photo);
  }

  const handleTextChange = (index: number, event: FormEvent<HTMLDivElement>) => {
    state.updateItem(index, event.currentTarget.innerText);
  };

  const togglePageDisabled = (pageIndex: number) => {
    console.log("toggle index page", pageIndex);
    const isPageEnabled = state.enabledPages.includes(pageIndex);
    if (isPageEnabled) {
      state.setEnabledPages(state.enabledPages.filter((item) => item !== pageIndex));
    } else {
      state.setEnabledPages(Array.from(new Set<number>([...state.enabledPages, pageIndex])).sort());
    }
    console.log("enabled pages", state.enabledPages);
  };

  return (
    <div className={styles.templateContainer}>
      {/* First Page */}
      <div className={styles.firstPageContainer}>
        <div className={styles.dividerWithText}>
          <LoveEditableDiv
            charLimit={20}
            onKeyDown={(e) => {
              handleTextChange(0, e);
            }}
            initialValue={initialState.items[0].data}
          />
          <span>EDITION</span>
        </div>
        <div className={styles.newspaperTitleSection}>
          <div className={styles.subtitle}>
            <p>"ALL THE LOVE</p> <p>THAT'S FIT TO PRINT"</p>
          </div>
          <div className={styles.title}>THE LOVE TIMES</div>
        </div>
        <div className={styles.doubleDivider} />
        <div className={styles.issueSection}>
          <div className={styles.vol}>VOL.4</div>
          <LoveEditableDiv
            className={styles.releaseDate}
            charLimit={45}
            onKeyDown={(e) => {
              handleTextChange(1, e);
            }}
            initialValue={initialState.items[1].data}
          />
          <LoveEditableDiv
            className={styles.city}
            charLimit={45}
            onKeyDown={(e) => {
              handleTextChange(2, e);
            }}
            initialValue={initialState.items[2].data}
          />
        </div>
        <div className={styles.divider} />
        <div className={styles.captionSection}>
          <LoveEditableDiv
            className={styles.caption}
            charLimit={45}
            onKeyDown={(e) => {
              handleTextChange(3, e);
            }}
            initialValue={initialState.items[3].data}
          />
          <LoveEditableDiv
            className={styles.subcaption}
            charLimit={80}
            onKeyDown={(e) => {
              handleTextChange(4, e);
            }}
            initialValue={initialState.items[4].data}
          />
        </div>
        <div className={styles.doubleDivider} />
        <div className={styles.headlineImage}>{renderImage(5)}</div>
        <div className={styles.doubleDivider} />
      </div>
      {/* End of First Page */}

      <br />

      {/* Second Page */}
      <div className={styles.disableButton}>
        <ToggleSwitch
          isEnabled={state.enabledPages.includes(1)}
          onChange={() => togglePageDisabled(1)}
          icon={state.enabledPages.includes(1) ? <IoEye size={14} color={"000"} /> : <IoEyeOff size={14} color={"000"} />}
        />
      </div>

      <div className={`${styles.secondPageContainer} ${!state.enabledPages.includes(1) ? styles.disabledPage : ""}`}>
        <div className={styles.divider} />
        <div className={styles.newspaperTitleSection}>
          <div className={styles.title}>THE LOVE TIMES</div>
        </div>
        <div className={styles.doubleDivider} />
        <div className={styles.bucketListSection}>
          <div className={styles.bucketListImageContainer}>
            <div className={styles.bucketListImage}>{renderImage(6)}</div>
            <div className={styles.bucketListImage} style={{ userSelect: "none" }}>
              {renderImage(7)}
            </div>
          </div>
          <div className={styles.bucketListContainer}>
            <div className={styles.bucketListTitle}>Our Bucket List</div>
            <div className={styles.bucketListDivider}></div>
            <div className={styles.bucketList}>
              <ul>
                <li>
                  <div className={styles.iconDiv}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
                    </svg>
                  </div>
                  <LoveEditableDiv
                    disabled={!state.enabledPages.includes(1)}
                    charLimit={70}
                    onKeyDown={(e) => {
                      handleTextChange(8, e);
                    }}
                    initialValue={initialState.items[8].data}
                  />
                </li>

                <li>
                  <div className={styles.iconDiv}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
                    </svg>
                  </div>
                  <LoveEditableDiv
                    disabled={!state.enabledPages.includes(1)}
                    charLimit={70}
                    onKeyDown={(e) => {
                      handleTextChange(9, e);
                    }}
                    initialValue={initialState.items[9].data}
                  />
                </li>
                <li>
                  <div className={styles.iconDiv}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
                    </svg>
                  </div>
                  <LoveEditableDiv
                    disabled={!state.enabledPages.includes(1)}
                    charLimit={70}
                    onKeyDown={(e) => {
                      handleTextChange(10, e);
                    }}
                    initialValue={initialState.items[10].data}
                  />
                </li>
                <li>
                  <div className={styles.iconDiv}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
                    </svg>
                  </div>
                  <LoveEditableDiv
                    disabled={!state.enabledPages.includes(1)}
                    charLimit={70}
                    onKeyDown={(e) => {
                      handleTextChange(11, e);
                    }}
                    initialValue={initialState.items[11].data}
                  />
                </li>
                <li>
                  <div className={styles.iconDiv}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
                    </svg>
                  </div>
                  <LoveEditableDiv
                    disabled={!state.enabledPages.includes(1)}
                    charLimit={70}
                    onKeyDown={(e) => {
                      handleTextChange(12, e);
                    }}
                    initialValue={initialState.items[12].data}
                  />
                </li>
                <li>
                  <div className={styles.iconDiv}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
                    </svg>
                  </div>
                  <LoveEditableDiv
                    disabled={!state.enabledPages.includes(1)}
                    charLimit={70}
                    onKeyDown={(e) => {
                      handleTextChange(13, e);
                    }}
                    initialValue={initialState.items[13].data}
                  />
                </li>
                <li>
                  <div className={styles.iconDiv}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
                    </svg>
                  </div>
                  <LoveEditableDiv
                    disabled={!state.enabledPages.includes(1)}
                    charLimit={70}
                    onKeyDown={(e) => {
                      handleTextChange(14, e);
                    }}
                    initialValue={initialState.items[14].data}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.favoriteMemorySection}>
          <div className={styles.favoriteMemoryTextSection}>
            <LoveEditableDiv
              disabled={!state.enabledPages.includes(1)}
              className={styles.favoriteMemoryTitle}
              charLimit={25}
              onKeyDown={(e) => {
                handleTextChange(15, e);
              }}
              initialValue={initialState.items[15].data}
            />
            <LoveEditableDiv
              disabled={!state.enabledPages.includes(1)}
              className={styles.favoriteMemoryText}
              onKeyDown={(e) => {
                handleTextChange(16, e);
              }}
              initialValue={initialState.items[16].data}
            />
          </div>
          <div className={styles.favoriteMemoryImageSection}>
            <div className={styles.favoriteMemoryImage}>{renderImage(17)}</div>
          </div>
        </div>
        <div className={styles.doubleDivider} />
      </div>
      {/* End of Second Page */}
      <br />
      {/* Third Page */}
      <div className={styles.disableButton}>
        <ToggleSwitch
          isEnabled={state.enabledPages.includes(2)}
          onChange={() => togglePageDisabled(2)}
          icon={state.enabledPages.includes(2) ? <IoEye size={14} color={"000"} /> : <IoEyeOff size={14} color={"000"} />}
        />
        {/* {!state.enabledPages.includes(2) ? "Enable this page" : "Disable this page"} */}
      </div>
      <div className={`${styles.thirdPageContainer} ${!state.enabledPages.includes(2) ? styles.disabledPage : ""}`}>
        <div className={styles.divider}></div>
        <div className={styles.newspaperTitleSection}>
          <div className={styles.title}>THE LOVE TIMES</div>
        </div>
        <div className={styles.doubleDivider} />
        <div className={styles.thirdPageHeadlineImage}>{renderImage(18)}</div>
        <div className={styles.divider} />
        <div className={styles.funFactSection}>
          <div className={styles.factsText}>LOVE FUN FACTS</div>
          <LoveEditableDiv
            disabled={!state.enabledPages.includes(2)}
            className={styles.funFacts}
            charLimit={35}
            onKeyDown={(e) => {
              handleTextChange(19, e);
            }}
            initialValue={initialState.items[19].data}
          />
          <LoveEditableDiv
            className={styles.funFacts}
            disabled={!state.enabledPages.includes(2)}
            charLimit={35}
            onKeyDown={(e) => {
              handleTextChange(20, e);
            }}
            initialValue={initialState.items[20].data}
          />
          <LoveEditableDiv
            className={styles.funFacts}
            charLimit={35}
            disabled={!state.enabledPages.includes(2)}
            onKeyDown={(e) => {
              handleTextChange(21, e);
            }}
            initialValue={initialState.items[21].data}
          />
        </div>
        <div className={styles.divider} />
        <div className={styles.favoriteThingsSection}>
          <div className={styles.favoriteThingsImage}>{renderImage(22)}</div>
          <div className={styles.favoriteThingsListSection}>
            <div className={styles.favoriteThingsTitle}>
              <LoveEditableDiv charLimit={40} disabled={!state.enabledPages.includes(2)} initialValue={initialState.items[23].data} />
            </div>
            <div className={styles.favoriteThingsList}>
              <ul>
                <li>
                  <div className={styles.iconDiv}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
                    </svg>
                  </div>
                  <LoveEditableDiv
                    disabled={!state.enabledPages.includes(2)}
                    charLimit={130}
                    className={styles.favoriteThingsListEditable}
                    initialValue={initialState.items[24].data}
                  />
                </li>
                <li>
                  <div className={styles.iconDiv}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
                    </svg>
                  </div>
                  <LoveEditableDiv
                    disabled={!state.enabledPages.includes(2)}
                    charLimit={130}
                    className={styles.favoriteThingsListEditable}
                    initialValue={initialState.items[25].data}
                  />
                </li>
                <li>
                  <div className={styles.iconDiv}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
                    </svg>
                  </div>
                  <LoveEditableDiv
                    disabled={!state.enabledPages.includes(2)}
                    charLimit={130}
                    className={styles.favoriteThingsListEditable}
                    initialValue={initialState.items[26].data}
                  />
                </li>
                <li>
                  <div className={styles.iconDiv}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
                    </svg>
                  </div>
                  <LoveEditableDiv
                    disabled={!state.enabledPages.includes(2)}
                    charLimit={130}
                    className={styles.favoriteThingsListEditable}
                    initialValue={initialState.items[27].data}
                  />
                </li>
                <li>
                  <div className={styles.iconDiv}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
                    </svg>
                  </div>
                  <LoveEditableDiv
                    disabled={!state.enabledPages.includes(2)}
                    charLimit={130}
                    className={styles.favoriteThingsListEditable}
                    initialValue={initialState.items[28].data}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.doubleDivider} />
      </div>
      {/* End of Third Page */}
    </div>
  );
});

export default TheLoveTimesTemplate;
