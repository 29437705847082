import { TemplateName } from "../Enums/TemplateName";

export type TemplatePreviews = {
  [key in TemplateName]: string;
};

export const getTemplatePreview = (template: TemplateName) => {
  const templatePreviews: TemplatePreviews = {
    ANNIVERSARY: "https://isaac-jenny.lovepage.io",
    TIMELINE: "https://marco-and-anna.lovepage.io",
    THE_LOVE_TIMES: "https://maria-my-love.lovepage.io",
  };

  return templatePreviews[template];
};

export const getTemplatePreviewImage = (template: TemplateName) => {
  const templateImagePreviews: TemplatePreviews = {
    ANNIVERSARY: "https://ik.imagekit.io/ex5o5cwj51/anni-1.jpeg?updatedAt=1685555650529",
    TIMELINE: "https://ik.imagekit.io/ex5o5cwj51/timeline-new.jpeg?updatedAt=1685816486832",
    THE_LOVE_TIMES: "https://ik.imagekit.io/ex5o5cwj51/the-love-times_n.png?updatedAt=1743713003256",
  };

  return templateImagePreviews[template];
};

export const getTemplateLocalizedKey = (template: TemplateName) => {
  const templateName: TemplatePreviews = {
    ANNIVERSARY: "anniversary",
    TIMELINE: "timeline-romance",
    THE_LOVE_TIMES: "the-love-times",
  };

  return templateName[template];
};
