import React, { FormEvent, HTMLAttributes, useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";

type Props = {
  charLimit?: number | undefined;
  initialValue?: string;
  disabled?: boolean;
};

const LoveEditableDiv = ({ charLimit = 0, initialValue, disabled = false, ...attributes }: Props & HTMLAttributes<HTMLDivElement>) => {
  const [content, setContent] = useState("");
  const [isOverLimit, setIsOverLimit] = useState(false);
  const _ref = useRef<HTMLDivElement>(null);

  const handleInputChange = (e: FormEvent<HTMLDivElement>) => {
    const text = e.currentTarget.innerText;

    if (charLimit && text.length > charLimit) {
      setIsOverLimit(true);
      e.currentTarget.innerText = content;
      const range = document.createRange();
      const selection = window.getSelection();
      if (_ref.current?.lastChild && selection) {
        range.selectNodeContents(_ref.current.lastChild);
        range.collapse(false);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    } else {
      setContent(text);
    }
  };

  useEffect(() => {
    if (isOverLimit) {
      const timer = setTimeout(() => setIsOverLimit(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [isOverLimit]);

  useEffect(() => {
    if (!_ref || !_ref.current) return;
    _ref.current.innerText = initialValue;
  }, []);

  return (
    <div
      ref={_ref}
      contentEditable={!disabled}
      spellCheck={false}
      suppressContentEditableWarning
      onInput={(e) => {
        handleInputChange(e);
        attributes.onInput(e);
      }}
      {...attributes}
      className={`${!disabled ? styles.editableDiv : ""} ${isOverLimit ? styles.error : ""} ${attributes.className}`}
    ></div>
  );
};

export default LoveEditableDiv;
