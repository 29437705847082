import React from "react";
import { ViewPageState } from "../../state/ViewPageState";
import { PageItemType } from "../../../edit/dto/PageItemType";
import PhotoView from "../../PhotoView";
import QuoteView from "../../QuoteView";
import { css } from "@emotion/css";
import { UpgradeBanner } from "../../UpgradeBanner";

type Props = {
    page: ViewPageState
    isAuthenticated: boolean
}

export const TimelinePage: React.FC<Props> = ({ page, isAuthenticated }) => {
  const [blur, setBlur] = React.useState(!isAuthenticated)

  React.useEffect(() => {
    if (isAuthenticated) {
      const timer = setTimeout(() => {
        setBlur(true)
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [isAuthenticated])

  const styles = css`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 3em;
    padding: 0;
    max-width: 540px;
  `

  const expiredStyles = css`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    background-color: transparent;
    backdrop-filter: blur(5px);
  `;


  return <div className={styles}>
    { !page.hasActivePlan && <UpgradeBanner isExpired={
      page.isExpired
    } /> }
    { blur && page.isExpired &&
      <div className={expiredStyles} />
    }
    {page.items.map((item, index) => {
      if (item.type === PageItemType.PHOTO) {
        return <PhotoView size="full"
          image={item.data} />
      } else {
        return <QuoteView text={item.data} />
      }
    })}
  </div>
}
