import React from 'react';
import { FaLock, FaTimes } from 'react-icons/fa';
import colors from '../colors';
import DefaultButton from '../common/DefaultButton';
import { useTranslation } from 'react-i18next';

interface Props {
  onClose: () => void;
  onUpgrade: () => void;
}

const PrivacyModePremiumDialog: React.FC<Props> = ({ onClose, onUpgrade }) => {
  const { t } = useTranslation();

  return (
    <div className="privacy-premium-dialog">
      <div className="heart1" />
      <div className="heart2" />
      <div className="heart3" />
      <div className="privacy-premium-content">
        <button className="close-button" onClick={onClose}>
          <FaTimes size={20} color={colors.white2} />
        </button>
        <div className="privacy-premium-icon">
          <FaLock size={48} color={colors.premiumPurple} />
        </div>
        <h2>Keep Your Love Story Private & Secure</h2>
        <div className="privacy-premium-features">
          <p>✨ Create a personal sanctuary for your memories</p>
          <p>🔒 Protect your intimate moments with a custom password</p>
          <p>💝 Share your love story only with those who matter</p>
        </div>
        <div className="privacy-premium-cta">
          <p>Upgrade to Premium and give your love story the privacy it deserves</p>
        </div>
        <div className="privacy-premium-button">
          <DefaultButton
            text="Upgrade Now"
            onClick={onUpgrade}
            color="gradient"
            size="medium"
          />
        </div>
      </div>
      <style>
        {`
          @keyframes float {
            0% {
              transform: translateY(100vh) translateX(0) rotate(0deg);
              opacity: 0;
            }
            10% {
              opacity: 1;
            }
            90% {
              opacity: 1;
            }
            100% {
              transform: translateY(-100vh) translateX(100px) rotate(360deg);
              opacity: 0;
            }
          }

          @keyframes float2 {
            0% {
              transform: translateY(100vh) translateX(0) rotate(0deg);
              opacity: 0;
            }
            10% {
              opacity: 1;
            }
            90% {
              opacity: 1;
            }
            100% {
              transform: translateY(-100vh) translateX(-100px) rotate(360deg);
              opacity: 0;
            }
          }

          @keyframes float3 {
            0% {
              transform: translateY(100vh) translateX(0) rotate(0deg);
              opacity: 0;
            }
            10% {
              opacity: 1;
            }
            90% {
              opacity: 1;
            }
            100% {
              transform: translateY(-100vh) translateX(50px) rotate(360deg);
              opacity: 0;
            }
          }

          .privacy-premium-dialog {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.7);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
            overflow: hidden;
          }

          .privacy-premium-dialog::before,
          .privacy-premium-dialog::after,
          .privacy-premium-dialog .heart1,
          .privacy-premium-dialog .heart2,
          .privacy-premium-dialog .heart3 {
            content: '❤';
            position: absolute;
            font-size: 24px;
            color: ${colors.premiumPurple};
            opacity: 0.8;
            animation: float 12s linear infinite;
            text-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
            transform: translateY(100vh);
            transform-origin: center;
          }

          .privacy-premium-dialog::before {
            left: 10%;
            animation-delay: 0s;
          }

          .privacy-premium-dialog::after {
            right: 10%;
            animation-delay: 3s;
          }

          .privacy-premium-dialog .heart1 {
            left: 20%;
            animation: float2 15s linear infinite;
            animation-delay: 2s;
          }

          .privacy-premium-dialog .heart2 {
            left: 50%;
            animation: float3 14s linear infinite;
            animation-delay: 5s;
          }

          .privacy-premium-dialog .heart3 {
            right: 20%;
            animation: float 13s linear infinite;
            animation-delay: 7s;
          }

          .privacy-premium-content {
            background: linear-gradient(135deg, 
              rgba(255, 192, 203, 0.15), 
              rgba(147, 112, 219, 0.15), 
              rgba(255, 182, 193, 0.15)
            );
            padding: 2rem;
            border-radius: 15px;
            width: 90%;
            max-width: 400px;
            text-align: center;
            box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
            position: relative;
            backdrop-filter: blur(10px);
            border: 1px solid rgba(255, 255, 255, 0.1);
            background-image: 
              radial-gradient(circle at 20% 20%, rgba(255, 255, 255, 0.05) 0%, transparent 50%),
              radial-gradient(circle at 80% 80%, rgba(255, 255, 255, 0.05) 0%, transparent 50%);
          }

          .close-button {
            position: absolute;
            top: 1rem;
            right: 1rem;
            background: none;
            border: none;
            cursor: pointer;
            padding: 0.5rem;
            border-radius: 50%;
            transition: background-color 0.2s;
          }

          .close-button:hover {
            background-color: rgba(255, 255, 255, 0.1);
          }

          .privacy-premium-icon {
            margin-bottom: 1.5rem;
          }

          .privacy-premium-content h2 {
            color: ${colors.white2};
            margin-bottom: 1.5rem;
            font-size: 1.5rem;
            font-weight: 600;
            text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          }

          .privacy-premium-features {
            text-align: left;
            margin-bottom: 1.5rem;
            padding: 0 1rem;
          }

          .privacy-premium-features p {
            color: ${colors.white2};
            margin: 0.8rem 0;
            font-size: 1.1rem;
            line-height: 1.4;
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
          }

          .privacy-premium-cta {
            margin-bottom: 1.5rem;
            padding: 0 1rem;
          }

          .privacy-premium-cta p {
            color: ${colors.white2};
            font-size: 1.1rem;
            line-height: 1.4;
            font-style: italic;
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
          }

          .privacy-premium-button {
            display: flex;
            justify-content: center;
          }
        `}
      </style>
    </div>
  );
};

export default PrivacyModePremiumDialog; 