import React from "react";
import { TemplateViewActions } from "./TemplateViewActions";
import { TemplateName } from "../../shared/Enums/TemplateName";
import { Ribbon } from "../common/Ribbon";
import { MdWorkspacePremium } from "react-icons/md";
import colors from "../colors";
import { getTemplatePreviewImage } from "../../shared/Types/TemplatePreviews";
import { RiTimelineView } from "react-icons/ri";
import { SiNewyorktimes } from "react-icons/si";

type Props = {
  showOverlay: boolean;
  templateName: TemplateName;
};

export const TemplateView: React.FC<Props> = ({ showOverlay, templateName }) => {
  const imgUrl = getTemplatePreviewImage(templateName);

  return (
    <div className="TemplateView">
      {templateName === TemplateName.TIMELINE && (
        <Ribbon color={colors.lightBlue}>
          <span className="Ribbon-Content">
            <RiTimelineView size={20} />
            <b>Timeline</b>
          </span>
        </Ribbon>
      )}

      {templateName === TemplateName.ANNIVERSARY && (
        <Ribbon color={colors.premiumPurple}>
          <span className="Ribbon-Content">
            <MdWorkspacePremium size={25} />
            <b>Anniversary</b>
          </span>
        </Ribbon>
      )}

      {templateName === TemplateName.THE_LOVE_TIMES && (
        <>
          <Ribbon color={colors.black2}>
            <span className="Ribbon-Content">
              <SiNewyorktimes size={20} />
              <b style={{marginLeft: "5px"}}>The Love Times</b>
            </span>
          </Ribbon>
          <div className="New-Ribbon">
            <span className="New-Ribbon-Content">
              <b>New</b>
            </span>
          </div>
        </>
      )}
      <img style={{ boxShadow: showOverlay && "0 0 10px 5px rgba(var(--info-rgb), 0.75)" }} className="TemplateView-Img" src={imgUrl} />
      {showOverlay && (
        <div className="TemplateView-Overlay">
          <div className="TemplateView-Overlay-Actions">
            <TemplateViewActions alignment="vertical" templateName={templateName} className={`TemplateView-Overlay-Actions-${templateName}`} />
          </div>
        </div>
      )}
      <style>
        {`
        .TemplateView {
            width: 100%;
            height: 100vh;
            max-width: 270px;
            max-height: 440px;
            box-sizing: border-box;
            position: relative;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: linear-gradient(90deg, #872640, #3a5f85);
        }
        .TemplateView-Img {
            width: 65vw;
            height: 66vh;
            max-width: 270px;
            max-height: 440px;
            object-fit: contain;
            border: 5px solid black;
            border-radius: 10px;
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
            box-sizing: border-box;
            display: flex;
        }
        .TemplateView-Overlay {
            position: absolute;
            top: 5px;
            left: 5px;
            width: calc(100% - 10px);
            height: calc(100% - 10px);
            box-sizing: border-box;
            background-color: rgba(0, 0, 0, 0.7);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
        }
        .TemplateView-Overlay-Actions {
            width: 100%;
            padding: 0 4px;
        }
        .Ribbon-Content {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 1.5em;
            color: ${colors.white};
        }
        .New-Ribbon {
            position: absolute;
            top: 0;
            left: 0;
            background-color: #e74c3c;
            padding: 5px 10px;
            border-radius: 0 0 10px 0;
            z-index: 10;
        }
        .New-Ribbon-Content {
            display: flex;
            align-items: center;
            font-size: 1em;
            color: ${colors.white};
        }
     `}
      </style>
    </div>
  );
};
