import React from "react";
import Dialog from "../dialog/Dialog";
import { SetPrivacyPasswordDialogBody } from "./SetPrivacyPasswordDialogBody";
import { SetPrivacyPasswordDialogFooter } from "./SetPrivacyPasswordDialogFooter";
import ErrorMessage from "../ErrorMessage";

type Props = {
  isSaveButtonSpin: boolean;
  onSave: (password: string) => void;
  onCancel: () => void;
  errorMessage?: string;
}

export const SetPrivacyPasswordDialog: React.FC<Props> = ({
  isSaveButtonSpin,
  onSave,
  onCancel,
  errorMessage,
}) => {
  return (
    <Dialog
      footer={
        <SetPrivacyPasswordDialogFooter
          isSaveButtonSpin={isSaveButtonSpin}
          onSave={onSave}
          onCancel={onCancel}
        />
      }
      onClose={() => !isSaveButtonSpin && onCancel()}
      isCloseDisabled={isSaveButtonSpin}
    >
      <SetPrivacyPasswordDialogBody />
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </Dialog>
  );
}; 