const ENCRYPTION_KEY = 'a19880f58c7932adfbcd074aa4ffe94d';

export class Encryption {
  private static async getKey(): Promise<CryptoKey> {
    const encoder = new TextEncoder();
    const keyData = encoder.encode(ENCRYPTION_KEY);
    const hash = await crypto.subtle.digest('SHA-256', keyData);
    return await crypto.subtle.importKey(
      'raw',
      hash,
      { name: 'AES-GCM' },
      false,
      ['encrypt', 'decrypt']
    );
  }

  static async encrypt(text: string): Promise<string> {
    try {
      const key = await this.getKey();
      const iv = crypto.getRandomValues(new Uint8Array(12));
      const encoder = new TextEncoder();
      const data = encoder.encode(text);
      
      const encryptedData = await crypto.subtle.encrypt(
        { name: 'AES-GCM', iv },
        key,
        data
      );
      
      const encryptedArray = new Uint8Array(encryptedData);
      const result = new Uint8Array(iv.length + encryptedArray.length);
      result.set(iv);
      result.set(encryptedArray, iv.length);
      
      // Convert Uint8Array to string without using spread operator
      const chars = new Array(result.length);
      for (let i = 0; i < result.length; i++) {
        chars[i] = result[i];
      }
      return btoa(String.fromCharCode.apply(null, chars));
    } catch (error) {
      console.error('Encryption failed:', error);
      return text; // Fallback to plain text if encryption fails
    }
  }

  static async decrypt(encryptedText: string): Promise<string> {
    try {
      const key = await this.getKey();
      const decoder = new TextDecoder();
      
      const data = Uint8Array.from(atob(encryptedText), c => c.charCodeAt(0));
      const iv = data.slice(0, 12);
      const encryptedData = data.slice(12);
      
      const decryptedData = await crypto.subtle.decrypt(
        { name: 'AES-GCM', iv },
        key,
        encryptedData
      );
      
      return decoder.decode(decryptedData);
    } catch (error) {
      console.error('Decryption failed:', error);
      return encryptedText; // Fallback to encrypted text if decryption fails
    }
  }
} 