import PageCreateDto from "../../api/dtos/PageCreateDto";
import { generateRandomString } from "../../common/GenerateHash";
import { TemplateName } from "../../shared/Enums/TemplateName";

export function getCreatePageDto(templateName: TemplateName): PageCreateDto {
  switch (templateName) {
    case TemplateName.TIMELINE:
      return {
        templateName,
        photos: [
          {
            data: "https://ik.imagekit.io/ex5o5cwj51/marco-22.jpg?updatedAt=1684147887488",
            pageIndex: 0,
            position: "left",
            key: generateRandomString(),
          },
          {
            data: "https://ik.imagekit.io/ex5o5cwj51/marco-33.jpg?updatedAt=1684147887509",
            pageIndex: 2,
            position: "left",
            key: generateRandomString(),
          },
        ],
        quotes: [
          {
            text: "You are my candle in the darkness..",
            pageIndex: 1,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "You have the key to my heart..",
            pageIndex: 3,
            position: "right",
            key: generateRandomString(),
          },
          {
            text: "I love you ♥️",
            pageIndex: 3,
            position: "right",
            key: generateRandomString(),
          },
        ],
        subdomain: generateSubdomain(),
      };

    case TemplateName.ANNIVERSARY:
      return {
        templateName,
        photos: [
          {
            data: "https://ik.imagekit.io/ex5o5cwj51/marco-22.jpg?updatedAt=1684147887488",
            pageIndex: 0,
            position: "left",
            key: generateRandomString(),
          },
          {
            data: "https://ik.imagekit.io/ex5o5cwj51/marco-33.jpg?updatedAt=1684147887509",
            pageIndex: 1,
            position: "left",
            key: generateRandomString(),
          },
          {
            data: "https://ik.imagekit.io/ex5o5cwj51/marco-11.jpg?updatedAt=1684147887534",
            pageIndex: 2,
            position: "left",
            key: generateRandomString(),
          },
          {
            data: "https://ik.imagekit.io/ex5o5cwj51/marco-44_1_.jpeg?updatedAt=1684934797941",
            pageIndex: 3,
            position: "left",
            key: generateRandomString(),
          },
        ],
        quotes: [
          {
            text: "Dec 2022",
            pageIndex: 4,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "Feb 2023",
            pageIndex: 5,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "March 2023",
            pageIndex: 6,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "May 2023",
            pageIndex: 7,
            position: "left",
            key: generateRandomString(),
          },
          {
            text:
              "I still smile when I think about our first date," + " when we laughed for hours over coffee and shared stories" + " about our lives.",
            pageIndex: 8,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "the past six months have been filled with" + " countless unforgettable moments with you.",
            pageIndex: 9,
            position: "left",
            key: generateRandomString(),
          },
          {
            text:
              "Our first weekend getaway. It was a time of deepening" +
              " our connection, discovering more about each other," +
              " and creating cherished memories together.",
            pageIndex: 10,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: " I can't wait to see what the future holds for us" + " as we continue to create a lifetime of" + " beautiful memories together.",
            pageIndex: 11,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "6th Month",
            pageIndex: 12,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "Anniversary",
            pageIndex: 13,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "and it is still counting..",
            pageIndex: 14,
            position: "left",
            key: generateRandomString(),
          },
        ],
        subdomain: generateSubdomain(),
      };

    case TemplateName.THE_LOVE_TIMES:
      return {
        templateName,
        enabledPages: [1, 2],
        photos: [
          {
            data: "https://ik.imagekit.io/ex5o5cwj51/marco-22.jpg?updatedAt=1684147887488",
            pageIndex: 5,
            position: "left",
            key: generateRandomString(),
          },
          {
            data: "https://ik.imagekit.io/ex5o5cwj51/marco-22.jpg?updatedAt=1684147887488",
            pageIndex: 6,
            position: "left",
            key: generateRandomString(),
          },
          {
            data: "https://ik.imagekit.io/ex5o5cwj51/marco-22.jpg?updatedAt=1684147887488",
            pageIndex: 7,
            position: "left",
            key: generateRandomString(),
          },
          {
            data: "https://ik.imagekit.io/ex5o5cwj51/marco-33.jpg?updatedAt=1684147887509",
            pageIndex: 17,
            position: "left",
            key: generateRandomString(),
          },
          {
            data: "https://ik.imagekit.io/ex5o5cwj51/marco-11.jpg?updatedAt=1684147887534",
            pageIndex: 18,
            position: "left",
            key: generateRandomString(),
          },
          {
            data: "https://ik.imagekit.io/ex5o5cwj51/marco-44_1_.jpeg?updatedAt=1684934797941",
            pageIndex: 22,
            position: "left",
            key: generateRandomString(),
          },
        ],
        quotes: [
          {
            text: "VALENTINES",
            pageIndex: 0,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "FRIDAY, FEBRUARY 14, 2025",
            pageIndex: 1,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "SAN FRANCISCO, CA",
            pageIndex: 2,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "WILL YOU BE MY VALENTINE?",
            pageIndex: 3,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "You make my heart happier every day!",
            pageIndex: 4,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "Our Bucket List",
            pageIndex: 8,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "Plan a weekend getaway to a cozy cabin or bed and breakfast",
            pageIndex: 9,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "Learn a new skill or hobby together, like dancing or cooking",
            pageIndex: 10,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "Explore a new city and be tourists for a day",
            pageIndex: 11,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "Attend a live performance, weher it's a concert or a play",
            pageIndex: 12,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "Take a scenic hike or nature walk in a picturesque location",
            pageIndex: 13,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "Volunteer together for a cause  you both care about",
            pageIndex: 14,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "MY FAVORITE MEMORY OF US",
            pageIndex: 15,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "There's something magical about the word \"home\". It's a place where dreams take root, where laughter echoes through the walls and where love quietly fills every corner. This year, we embarked on a journey that changed our lives. -we bought our very first home together.",
            pageIndex: 16,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "THE DAY WE MET\nOctober 23, 2019",
            pageIndex: 19,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "OUR FIRST KISS\nSeptember 8, 2020",
            pageIndex: 20,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "OUR FIRST DATE \n December 4, 2019",
            pageIndex: 21,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "MY FAVORITE THINGS ABOUT YOU!",
            pageIndex: 23,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "Bringing me coffee in the morning, just the way I like it",
            pageIndex: 24,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "Listening to me, even when I ramble about small things",
            pageIndex: 25,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "Surprising me with my favorite snacks",
            pageIndex: 26,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "Holding my hand while you are driving",
            pageIndex: 27,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "Supporting me in everything I do, no matter how big or small",
            pageIndex: 28,
            position: "left",
            key: generateRandomString(),
          },
        ],
        subdomain: generateSubdomain(),
      };
    default:
      throw new Error("Invalid template name");
  }
}

function generateSubdomain(): string {
  return makeid(12);
}

function makeid(length) {
  let result = "";
  const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
