import React from "react";
import { Link } from "../common/Link";
import { SocialLinks } from "../page/SocialLinks";
import styles from "./styles.module.css";

export const Footer = () => {
  return (
    <div className={styles.footer}>
      <Link href="https://privacy-policy.lovepage.io" text="Privacy Policy" />
      <Link href="https://terms-of-use.lovepage.io/t" text="Terms of Use" />
      <Link href="https://blog.lovepage.io" text="Blog" />
      <SocialLinks />© 2024 lovepage.io
    </div>
  );
};
