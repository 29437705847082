import React, { useContext, useEffect, useState } from "react";
import { validateSignInCode } from "../../api/AuthApi";
import { AppContext, AppContextAction } from "../AppContext";
import { useNavigate } from "react-router-dom";
import { LoadingState } from "../../common/LoadingState";
import colors from "../colors";
import { Footer } from "../Footer/Footer";
import { Spinner } from "../common/Spinner";
import ErrorMessage from "../ErrorMessage";
import Header from "../header/Header";

const SignInLink: React.FC = () => {
  const { dispatch } = useContext(AppContext);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const [isValidateInProgress, setValidateInProgress] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("t");
    const isSale = urlParams.get("isSale") == "fp";
    localStorage.setItem("isSale", isSale.toString());

    setValidateInProgress(true);
    runValidateRequest(code);

    async function runValidateRequest(code: string) {
      try {
        if (code) {
          const user = await validateSignInCode(code);
          dispatch({
            type: AppContextAction.SIGN_IN,
            userDto: { state: LoadingState.LOADED, data: user },
          });
          setTimeout(() => {
            navigate("/dashboard");
          }, 800);
        }
      } catch (error) {
        setErrorMessage(error?.response?.data?.message || "Server is offline, we are working on it");
      } finally {
        setValidateInProgress(false);
      }
    }
  }, []);

  return (
    <>
      <Header />
      <div className="SignInLink">
        {isValidateInProgress && <Spinner />}
        {!isValidateInProgress && errorMessage === null && (
          <>
            <h3 className="success-header">
              Success <span>✅</span>
            </h3>
            <p className="redirection-info">You are being redirected..</p>
            <div>
              <Spinner />
            </div>
          </>
        )}
        {!isValidateInProgress && errorMessage !== null && (
          <>
            <h3 className="success-header">
              Error <span>⛔️</span>
            </h3>
            <p className="redirection-info">
              <ErrorMessage message={errorMessage} />
            </p>
          </>
        )}
        <style jsx>
          {`
            .SignInLink {
              display: flex;
              flex-direction: column;
              justify-content: start;
              align-items: center;
              gap: 1em;
              height: 80vh;
              padding-bottom: 5em;
            }
            .success-header {
              color: ${colors.white2};
              font-size: 2em;
              font-weight: 600;
              background: -webkit-linear-gradient(45deg, #827ffc, #fb7f82 80%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            .success-header > span {
              background: white;
              -webkit-background-clip: unset;
              -webkit-text-fill-color: white;
            }
            .redirection-info {
              color: ${colors.white2};
              font-size: 1.5em;
              font-weight: 600;
              background: -webkit-linear-gradient(45deg, #827ffc, #fb7f82 80%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          `}
        </style>
      </div>
      <Footer />
    </>
  );
};

export default SignInLink;
