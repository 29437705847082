import { css } from "@emotion/css";
import React from "react";
import colors from "../colors";
import { MdArrowForwardIos } from "react-icons/md";
import { isProduction } from "../../config";

type Props = {
  isExpired: boolean
}

export const UpgradeBanner: React.FC<Props> = ({ isExpired }) => {
  const url = isProduction ? "https://lovepage.io" : "http://localhost:3000"
  const redirectUrl = `${url}/upgrade-redirect`

  const styles = css`
        background-color: ${colors.lightBlue};
        font-size: 16px;
        color: ${colors.white2};
        position: fixed;
        top: 0;
        width: 100%;
        line-height: 1.5em;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        box-sizing: border-box;
        padding: 5px 10px;
        cursor: pointer;
        .Upgrade-Line {
            display: flex;
            align-items: center;
        }
        @media (min-width: 750px) {
            justify-content: center;
        }
        z-index: 10;
    `

  return <a href={redirectUrl} className={styles}>
    <span>
      {!isExpired &&<> <b>6 Hours Free Trial is active.</b><br/></>}
      {isExpired &&<> <b>Your Free Trial has ended.</b><br/></>}
      <span className="Upgrade-Line">
        Purchase The Premium Plan to Keep Your Website
        <MdArrowForwardIos size={20} />
      </span>
    </span>
  </a>
}
