import React, { InputHTMLAttributes, useEffect, useRef } from "react";

type Props = {
  // onChange: (value: string) => void;
  onBlur?: () => void;
  defaultValue: string;
  placeholder?: string;
  maxWidth?: string;
  focus?: boolean;
  password?: boolean;
  textAlign?: string;
  isError?: boolean;
  autoComplete?: string;
  className?: string;
} & InputHTMLAttributes<HTMLInputElement>;

const Input: React.FC<Props> = ({
  // onChange,
  defaultValue,
  maxWidth = "500px",
  focus = false,
  password = false,
  textAlign = "center",
  placeholder = "",
  isError = false,
  onBlur = () => null,
  autoComplete = "",
  className = "Input",
  ...attributes
}) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focus) {
      ref.current?.focus();
    }
  }, [focus, ref]);

  // function handleChange(event: ChangeEvent<HTMLInputElement>) {
  //   onChange(event.target.value);
  // }

  // @ts-ignore
  return (
    <>
      <input
        ref={ref}
        // ref={ref.current}
        onBlur={onBlur}
        placeholder={placeholder}
        autoComplete={autoComplete}
        className={className}
        defaultValue={defaultValue}
        type={password ? "password" : ""}
        {...attributes}
      />
      <style jsx>
        {`
          .${className} {
            min-height: 34px;
            background: inherit;
            color: rgba(var(--purple-rgb), 0.9);
            font-size: 30px;
            width: 100%;
            min-width: fit-content;
            font-size: larger;
            max-width: ${maxWidth};
            padding: 3px 5px 3px 5px;
            min-width: 30px;
            text-align: ${textAlign};
            border: none;
            border-radius: var(--border-radius);
            box-shadow: ${isError ? "0 0 5px 0 var(--danger-font-color)" : "0 0 4px 0 rgba(var(--purple-rgb), 0.8)"};
            outline-style: none;
          }
          .${className}::placeholder {
            color: var(--pink-color);
            font-size: 25px;
          }
          .${className}:focus {
            box-shadow: 0 0 5px 0 var(--pink-color);
          }
        `}
      </style>
    </>
  );
};

export default Input;
