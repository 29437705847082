import { Encryption } from './Encryption';

export class PasswordStorage {
  private static readonly STORAGE_KEY = 'love_page_passwords';

  static async savePassword(subdomain: string, password: string): Promise<void> {
    try {
      const passwords = this.getAllPasswords();
      const encryptedPassword = await Encryption.encrypt(password);
      passwords[subdomain] = encryptedPassword;
      sessionStorage.setItem(this.STORAGE_KEY, JSON.stringify(passwords));
    } catch (error) {
      console.error('Failed to save password:', error);
    }
  }

  static async getPassword(subdomain: string): Promise<string | null> {
    try {
      const passwords = this.getAllPasswords();
      const encryptedPassword = passwords[subdomain];
      if (!encryptedPassword) return null;
      return await Encryption.decrypt(encryptedPassword);
    } catch (error) {
      console.error('Failed to get password:', error);
      return null;
    }
  }

  static removePassword(subdomain: string): void {
    try {
      const passwords = this.getAllPasswords();
      delete passwords[subdomain];
      sessionStorage.setItem(this.STORAGE_KEY, JSON.stringify(passwords));
    } catch (error) {
      console.error('Failed to remove password:', error);
    }
  }

  private static getAllPasswords(): Record<string, string> {
    try {
      const stored = sessionStorage.getItem(this.STORAGE_KEY);
      return stored ? JSON.parse(stored) : {};
    } catch (error) {
      console.error('Failed to get all passwords:', error);
      return {};
    }
  }
} 