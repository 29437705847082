import React, { useContext, useState } from "react";
import { AddressBar } from "../edit/AddressBar";
import DefaultButton from "../common/DefaultButton";
import colors from "../colors";
import { useNavigate } from "react-router-dom";
import { WebPageItemDropdown } from "./WebPageItemDropdown";
import { deletePage, setPrivacyMode } from "../../api/PageAPI";
import { LoadingState } from "../../common/LoadingState";
import { AppContext, AppContextAction } from "../AppContext";
import { QrCodeDialog } from "./QrCodeDialog";
import Dialog from "../dialog/Dialog";
import { DeletePageDialogBody } from "./DeletePageDialogBody";
import { DeletePageDialogFooter } from "./DeletePageDialogFooter";
import { css } from "@emotion/css";
import { TemplateName } from "../../shared/Enums/TemplateName";
import { useTranslation } from "react-i18next";
import { SetPrivacyPasswordDialog } from "./SetPrivacyPasswordDialog";
import { IoLockClosed, IoLockOpen } from "react-icons/io5";
import { DisablePrivacyDialog } from "./DisablePrivacyDialog";
import ToggleSwitch from "../common/ToggleSwitch/ToggleSwitch";
import PrivacyModePremiumDialog from "./PrivacyModePremiumDialog";
import { FeatureDistributionService } from "../../services/FeatureDistributionService";
import { ExpiryCountdown } from "../common/ExpiryCountdown";
import { getTemplateLocalizedKey } from "../../shared/Types/TemplatePreviews";

type Props = {
  pageId: number;
  index: number;
  templateName: TemplateName;
  isPrivacyModeEnabled?: boolean;
};

export const WebPageItem: React.FC<Props> = ({ pageId, index, templateName, isPrivacyModeEnabled = false }) => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const [showQrCodeDialog, setShowQrCodeDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showPrivacyDialog, setShowPrivacyDialog] = useState(false);
  const [isDeleteButtonSpin, setDeleteButtonSpin] = useState(false);
  const [isPrivacyButtonSpin, setPrivacyButtonSpin] = useState(false);
  const [privacyErrorMessage, setPrivacyErrorMessage] = useState("");
  const { t } = useTranslation();
  const [showDisablePrivacyDialog, setShowDisablePrivacyDialog] = useState(false);
  const [isDisablePrivacyButtonSpin, setDisablePrivacyButtonSpin] = useState(false);
  const [showPremiumDialog, setShowPremiumDialog] = useState(false);
  const hasActivePlan = state.pages.state === LoadingState.LOADED && state.pages.data.find((p) => p.id === pageId)?.hasActivePlan;
  const userId = state.userDto.state === LoadingState.LOADED ? state.userDto.data.id : null;
  const requiresPremium = FeatureDistributionService.isPrivacyModeRequiringPremium(userId);
  const page = state.pages.state === LoadingState.LOADED ? state.pages.data.find((p) => p.id === pageId) : null;

  console.log("hasactiveplan", hasActivePlan);

  const expiryDate = React.useMemo(() => new Date(page.expiryDate), [page.expiryDate]);
  function handleGenerateQrCodeClick() {
    setShowQrCodeDialog(true);
  }

  function handleEditClick() {
    navigate(`/edit?pageId=${pageId}`);
  }

  function handleDeleteConfirm() {
    setDeleteButtonSpin(true);
    deletePage(pageId)
      .then(() => {
        setDeleteButtonSpin(false);
        setShowDeleteDialog(false);
        if (state.pages.state !== LoadingState.LOADED) return;
        const updatedPages = state.pages.data.filter((page) => page.id !== pageId);
        dispatch({
          type: AppContextAction.PAGE_LOAD,
          pages: { state: LoadingState.LOADED, data: updatedPages },
        });
      })
      .catch((e) => {
        setDeleteButtonSpin(false);
      });
  }

  const handlePrivacyToggle = async (enabled: boolean) => {
    if (requiresPremium && !hasActivePlan) {
      setShowPremiumDialog(true);
      return;
    }

    if (enabled) {
      setShowPrivacyDialog(true);
    } else {
      setShowDisablePrivacyDialog(true);
    }
  };

  function handlePrivacySave(password: string) {
    setPrivacyButtonSpin(true);
    setPrivacyErrorMessage("");
    setPrivacyMode(pageId, true, password)
      .then((response) => {
        if (response.status === 200) {
          setPrivacyButtonSpin(false);
          setShowPrivacyDialog(false);
          if (state.pages.state !== LoadingState.LOADED) return;
          const updatedPages = state.pages.data.map((page) => (page.id === pageId ? { ...page, isPrivacyModeEnabled: true } : page));
          dispatch({
            type: AppContextAction.PAGE_LOAD,
            pages: { state: LoadingState.LOADED, data: updatedPages },
          });
        } else {
          setPrivacyErrorMessage(t("Operation failed, please try again later"));
        }
      })
      .catch(() => {
        setPrivacyButtonSpin(false);
        setPrivacyErrorMessage(t("Operation failed, please try again later"));
      });
  }

  function handlePrivacyCancel() {
    setShowPrivacyDialog(false);
    setPrivacyButtonSpin(false);
    setPrivacyErrorMessage("");
  }

  function handleDisablePrivacyConfirm() {
    setDisablePrivacyButtonSpin(true);
    setPrivacyErrorMessage("");
    setPrivacyMode(pageId, false)
      .then((response) => {
        if (response.status === 200) {
          setDisablePrivacyButtonSpin(false);
          setShowDisablePrivacyDialog(false);
          if (state.pages.state !== LoadingState.LOADED) return;
          const updatedPages = state.pages.data.map((page) => (page.id === pageId ? { ...page, isPrivacyModeEnabled: false } : page));
          dispatch({
            type: AppContextAction.PAGE_LOAD,
            pages: { state: LoadingState.LOADED, data: updatedPages },
          });
        } else {
          setPrivacyErrorMessage(t("Operation failed, please try again later"));
        }
      })
      .catch(() => {
        setDisablePrivacyButtonSpin(false);
        setPrivacyErrorMessage(t("Operation failed, please try again later"));
      });
  }

  function handleDisablePrivacyCancel() {
    setShowDisablePrivacyDialog(false);
    setDisablePrivacyButtonSpin(false);
    setPrivacyErrorMessage("");
  }

  function handleQrDialogClose() {
    setShowQrCodeDialog(false);
  }

  function handleDeletePageClick() {
    setShowDeleteDialog(true);
  }

  const handleUpgradeClick = () => {
    setShowPremiumDialog(false);
    navigate("/pricing");
  };

  const deletePageFooter = (
    <DeletePageDialogFooter
      isDeleteButtonSpin={isDeleteButtonSpin}
      onDeleteCancel={() => setShowDeleteDialog(false)}
      onDeleteConfirm={handleDeleteConfirm}
    />
  );

  const templateNameStr = t(getTemplateLocalizedKey(templateName)); //templateName === TemplateName.TIMELINE ? t("timeline-romance") : t("anniversary");

  const pageNameStyle = css`
    background-color: ${templateName === TemplateName.TIMELINE ? colors.lightBlue : colors.premiumPurple};
    border: none;
    padding: 5px 10px;
    border-radius: 15px;
    font-weight: bold;
  `;

  return (
    <div className="WebPageItem">
      <div className="WebPageItem-Container">
        {!hasActivePlan && <ExpiryCountdown expiryDate={expiryDate} />}
        <div className="WebPageItem-Header">
          <div className={pageNameStyle}>{`#${index + 1} - ${templateNameStr} ${t("page")}`}</div>
          <div className="WebPageItem-Header-Actions">
            <div className="privacy-toggle-container">
              <span className="sr-only">Privacy Mode</span>
              <ToggleSwitch
                isEnabled={isPrivacyModeEnabled}
                onChange={handlePrivacyToggle}
                disabled={isPrivacyButtonSpin}
                icon={
                  isPrivacyModeEnabled ? <IoLockClosed size={14} color={colors.premiumPurple} /> : <IoLockOpen size={14} color={colors.lightBlue} />
                }
              />
            </div>
          </div>
        </div>
        <div className="WebPageItem-Link">
          <AddressBar pageId={pageId} />
        </div>
        <div className="WebPageItem-Actions">
          <div className="WebPageItem-Action-Item">
            <DefaultButton className="WebPageItem-Edit-Button" size="medium" color="gradient" text="Edit" onClick={handleEditClick} />
          </div>
          <div className="WebPageItem-Action-Item">
            <WebPageItemDropdown
              onDeleteClick={handleDeletePageClick}
              onQrCodeClick={handleGenerateQrCodeClick}
              className={`PageLinkDropdown-${pageId}`}
              pageId={pageId}
            />
          </div>
        </div>
        {showQrCodeDialog && <QrCodeDialog pageId={pageId} onClose={handleQrDialogClose} />}
        {showDeleteDialog && (
          <Dialog footer={deletePageFooter} onClose={() => (isDeleteButtonSpin ? null : setShowDeleteDialog(false))}>
            <DeletePageDialogBody />
          </Dialog>
        )}
        {showPrivacyDialog && (
          <SetPrivacyPasswordDialog
            isSaveButtonSpin={isPrivacyButtonSpin}
            onSave={handlePrivacySave}
            onCancel={handlePrivacyCancel}
            errorMessage={privacyErrorMessage}
          />
        )}
        {showDisablePrivacyDialog && (
          <DisablePrivacyDialog
            isConfirmButtonSpin={isDisablePrivacyButtonSpin}
            onConfirm={handleDisablePrivacyConfirm}
            onCancel={handleDisablePrivacyCancel}
          />
        )}
        {showPremiumDialog && <PrivacyModePremiumDialog onClose={() => setShowPremiumDialog(false)} onUpgrade={handleUpgradeClick} />}
      </div>
      <style>
        {`
          .WebPageItem {
            position: relative;
            height: 12em;
            width: 100%;
            border: 1px solid ${colors.white2};
            background-color: rgb(216 122 241 / 20%);
            box-sizing: border-box;
            border-radius: 1rem;
            padding: 1em;
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: space-between;
            box-shadow: 0 0 1px ${colors.white2};
            gap: 8px;
          }

          .WebPageItem-Container {
            position: relative;
            width: 100%;
            height: 100%;
          }

          .WebPageItem-Header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom: 8px;
          }

          .WebPageItem-Header-Actions {
            display: flex;
            align-items: center;
            gap: 16px;
          }

          .WebPageItem-Link {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: start;
            width: 100%;
          }

          .WebPageItem-Actions {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: start;
            gap: 5px;
            height: 3em;
            padding-top: 10px;
          }

          .WebPageItem-Action-Item {
            width: fit-content;
            height: 100%;
          }

          .privacy-toggle-container {
            display: flex;
            align-items: center;
          }

          .sr-only {
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            white-space: nowrap;
            border: 0;
          }
        `}
      </style>
    </div>
  );
};
