import React from "react";
import { FaSpinner } from "react-icons/fa";

export const Spinner: React.FC = () => {
  return (
    <div className="Spinner-Container">
      <FaSpinner data-testid="page-save-button-spinner" className="Spinner" size="100" color={"#fb7f82"} />
      <style>
        {`
            @keyframes spinner {
                0% {
                    transform: translate3d(0, 0, 0) rotate(0deg);
                }
                100% {
                    transform: translate3d(0, 0, 0) rotate(360deg);
                }
            }
            .Spinner {
                animation: 1.5s linear infinite spinner;
            }
            .Spinner-Container {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            }
        `}
      </style>
    </div>
  );
};
