import React, { useContext, useEffect, useState } from "react";
import Input from "../common/Input";
import { getMe, signIn } from "../../api/AuthApi";
import { AppContext, AppContextAction } from "../AppContext";
import ErrorMessage from "../ErrorMessage";
import { useNavigate } from "react-router-dom";
import { LoadingState } from "../../common/LoadingState";
import DefaultButton from "../common/DefaultButton";
import { BiLogIn } from "react-icons/bi";
import colors from "../colors";
import { Footer } from "../Footer/Footer";
import Header from "../header/Header";
import { validateEmail } from "./Validator.util";
import { SignInResultStatus } from "../../api/dtos/SignInResultStatus";

const SignIn: React.FC = () => {
  const [email, setEmail] = useState("");
  const { dispatch } = useContext(AppContext);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [isSigninButtonSpin, setSigninButtonSpin] = useState(false);

  function handleEmailChange(value: string) {
    setErrorMessage("");
    setEmail(value);
  }

  async function handleSignInClick(): Promise<void> {
    try {
      setSigninButtonSpin(true);
      const signInResult = await signIn({
        email,
      });

      if (signInResult.status === SignInResultStatus.USER_CREATED) {
        const user = await getMe();
        dispatch({
          type: AppContextAction.SIGN_IN,
          userDto: { state: LoadingState.LOADED, data: user },
        });
        navigate("/dashboard");
      } else if (signInResult.status === SignInResultStatus.SIGN_IN_LINK_SENT) {
        navigate("/sign-in-link-sent");
        return;
      } else if (signInResult.status === SignInResultStatus.SIGN_IN_FAILED) {
        setErrorMessage("Sign in failed, please try again");
        return;
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message || "Server is offline, we are working on it");
      console.error(error);
      return;
    } finally {
      setSigninButtonSpin(false);
    }
  }

  const signInIcon = <BiLogIn color={colors.white2} className="Sign-In-Icon" size={35} />;

  return (
    <>
      <div className="SignIn">
        <Header />
        <h2 className="SignIn-Title">Enter an email address</h2>
        <div className="SignIn-Email row">
          <Input
            placeholder="Email address"
            onChange={(e) => handleEmailChange(e.target.value)}
            isError={!!email && validateEmail(email) !== null}
            defaultValue={email}
            textAlign="left"
          />
        </div>
        <div className="SignIn-Button row">
          <DefaultButton
            icon={signInIcon}
            isSpin={isSigninButtonSpin}
            disabled={isSigninButtonSpin || validateEmail(email) !== null}
            className="SignIn-Button-SignIn-Btn"
            color="gradient"
            text="Continue"
            onClick={handleSignInClick}
          />
        </div>
        <ErrorMessage message={errorMessage} />
        <style jsx>
          {`
            .SignIn-Title {
              padding: 1em 0 0.5em 0;
              color: rgba(var(--pink-rgb), 0.9);
            }
            .SignIn {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              background: var(--white);
              width: 100%;
              color: var(--gray-800);
              padding-bottom: 4em;
            }
            .row {
              display: flex;
              justify-content: center;
              margin: 10px 0;
            }
            .SignIn-Button {
              display: flex;
              flex-direction: column;
              max-width: 325px;
            }
          `}
        </style>
      </div>
      <Footer />
    </>
  );
};

export default SignIn;
