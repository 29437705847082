import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteAccount } from "../../api/UserApi";
import { AppContext } from "../AppContext";
import DefaultButton from "../common/DefaultButton";
import { useActivePlan } from "../dashboard/useActivePlan";
import { AppContextAction } from "../AppContext";
import { LoadingState } from "../../common/LoadingState";
import { Spinner } from "../common/Spinner";
import AuthHeader from "../header/AuthHeader";
import { DeleteAccountDialog } from "./DeleteAccountDialog";
import { RefundDialog } from "./RefundDialog";
import { MdOutlineInfo, MdSettings, MdWorkspacePremium } from "react-icons/md";
import colors from "../colors";
import { RiDeleteBinLine } from "react-icons/ri";
import { PlanType } from "../../api/dtos/PlanType";
import { EnterEmailButton } from "../common/EnterEmailButton";
import LoveBox from "../LoveBox/LoveBox";

const Account: React.FC = () => {
  const { plan } = useActivePlan();
  const { state, dispatch } = useContext(AppContext);
  const [showRefundDialog, setShowRefundDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleteConfirmSpin, setDeleteConfirmSpin] = useState(false);

  const navigate = useNavigate();

  function handleDeleteAccountClick(event: React.MouseEvent<HTMLButtonElement>) {
    setShowDeleteDialog(true);
  }

  function handleRefundClick(event: React.MouseEvent<HTMLButtonElement>): void {
    setShowRefundDialog(true);
  }

  function handleRefundConfirm() {
    setShowRefundDialog(false);
  }

  function handleDeleteCancel(): void {
    setShowDeleteDialog(false);
  }

  async function handleDeleteConfirm(): Promise<void> {
    try {
      setDeleteConfirmSpin(true);
      await deleteAccount();
      dispatch({ type: AppContextAction.LOGOUT });
      navigate("/");
    } catch (error) {
      console.error(error);
    } finally {
      setDeleteConfirmSpin(false);
      setShowDeleteDialog(false);
    }
  }

  function handleEnterEmailClick() {
    console.log("handleEnterEmailClick");

    sessionStorage.setItem("redirectAfterEmail", "/account");
  }

  const refundButtonIcon = <MdOutlineInfo size={25} color={colors.white2} />;

  const deleteAccountButtonIcon = <RiDeleteBinLine size={25} color="black" />;

  const showEnterEmailButton = state.userDto.state === LoadingState.LOADED && state.userDto.data?.email === state.userDto.data?.deviceId;

  const account = state.userDto.state === LoadingState.LOADED && (
    <>
      <AuthHeader />
      <div className="Account">
        <LoveBox>
          <div className="Account-Content">
            <h2>Account Details</h2>
            <div className="Account-Row">
              <div className="Account-Row-First-Column">
                <label htmlFor="account-email">Email Address: </label>
              </div>
              <div className="Account-Row-Second-Column">
                {showEnterEmailButton && (
                  <div onClick={handleEnterEmailClick} className="Enter-Email-Button">
                    <EnterEmailButton />
                  </div>
                )}
                {showEnterEmailButton === false && <div id="account-email">{state.userDto.data.email || "-"}</div>}
              </div>
            </div>
            <div className="Account-Row">
              <div className="Account-Row-First-Column">
                <label htmlFor="account-plan">Plan: </label>
              </div>
              <div className="Account-Row-Second-Column">
                <div id="account-plan">{plan}</div>
              </div>
            </div>

            <h3>Account Actions:</h3>
            <div className="Account-Row">
              <div className="Account-Row-Button">
                <DefaultButton
                  text="Request Refund"
                  className="Request-Refund-DefaultButton"
                  size="medium"
                  color="blue"
                  icon={refundButtonIcon}
                  onClick={handleRefundClick}
                />
              </div>
              {showRefundDialog && <RefundDialog onClose={handleRefundConfirm} />}
            </div>
            <div className="Account-Row">
              <div className="Account-Row-Button">
                <DefaultButton
                  text="Delete Account"
                  className="Delete-Account-DefaultButton"
                  size="medium"
                  icon={deleteAccountButtonIcon}
                  onClick={handleDeleteAccountClick}
                />
              </div>
              {showDeleteDialog && (
                <DeleteAccountDialog
                  isDeleteButtonSpin={isDeleteConfirmSpin}
                  onDeleteConfirm={handleDeleteConfirm}
                  onDeleteCancel={handleDeleteCancel}
                />
              )}
            </div>
          </div>
        </LoveBox>

        <style jsx>
          {`
            .Account {
              display: flex;
              flex-direction: column;
              width: 100%;
              max-width: 750px;
              color: rgba(var(--pink-rgb), 0.9);
              font-size: 18px;
            }
            .Account-Content {
              display: flex;
              padding: 1em;
              flex-direction: column;
            }
            .Account-Delete-Message {
              font-size: 1em;
              font-height: 1.5em;
              font-weight: 450;
            }
            .Account-Row {
              display: flex;
              flex-direction: column;
              width: 100%;
              justify-content: center;
              align-items: flex-start;
              padding: 1.5em 0;
              gap: 10px;
            }
            .Account-Row-First-Column {
              width: 100%;
              text-align: left;
              font-weight: bold;
            }
            .Account-Row-Second-Column {
              width: 100%;
              text-align: left;
            }
            .Account-Row-Second-Column > div {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              word-break: break-all;
            }
            .Account-Row-Button {
              width: 200px;
            }
            .Manage-Subscription-Link {
              text-decoration: none;
            }
            .Upgrade-Subscription-Link {
              text-decoration: none;
            }
            div .Enter-Email-Button {
              width: 10em;
            }
            .Account * > h2,
            h3,
            label {
              background: -webkit-linear-gradient(45deg, #827ffc, #fb7f82 80%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              width: max-content;
            }
          `}
        </style>
      </div>
    </>
  );

  switch (state.userDto.state) {
    case LoadingState.IDLE:
    case LoadingState.LOADING:
      return <Spinner />;
    case LoadingState.LOADED:
      return account;
    case LoadingState.NO_AUTH:
      navigate("/sign-in");
      return <Spinner />;
    default:
      return <Spinner />;
  }
};

export default Account;
