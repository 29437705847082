import React, { useContext, useEffect, useState } from "react";
import { getPages } from "../../api/PageAPI";
import PageDto from "../../api/dtos/PageDto";
import { AppContext, AppContextAction } from "../AppContext";
import { AxiosResponse } from "axios";
import DefaultButton from "../common/DefaultButton";
import colors from "../colors";
import { useNavigate } from "react-router-dom";
import { LoadingState } from "../../common/LoadingState";
import { Spinner } from "../common/Spinner";
import AuthHeader from "../header/AuthHeader";
import { WebPageItem } from "./WebPageItem";
import { BiPlus } from "react-icons/bi";
import { UpgradeModal } from "../upgrade/UpgradeModal";
import { useTranslation } from "react-i18next";
import { PlanType } from "../../api/dtos/PlanType";

const Profile: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const navigate = useNavigate();

  const pagesData = state.pages.state === LoadingState.LOADED ?
    state.pages.data :
    null;

  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const { t } = useTranslation();

  const deviceId = state.userDto.state === LoadingState.LOADED ?
   state.userDto.data?.deviceId : null;
  const userEmail = state.userDto.state === LoadingState.LOADED ?
   state.userDto.data?.email : null;
  const isLoaded = state.userDto.state === LoadingState.LOADED;
  const isDeviceUser = isLoaded && deviceId === userEmail;

  const isSale = localStorage.getItem("isSale") === "true" &&
    state.userDto.state === LoadingState.LOADED &&
    state.userDto.data.plan === PlanType.FREE &&
    new Date() < new Date("2025-03-14T00:00:00");

  if (state.userDto.state === LoadingState.NO_AUTH) {
    navigate("/sign-in");
  }

  useEffect(() => {
    const hasSelectedPremium = !!localStorage.getItem("selectedPremium");
    const hasUpgradeBanner = !!localStorage.getItem("isUpgradeBannerClicked");

    if (hasSelectedPremium || hasUpgradeBanner) {
      setShowUpgradeModal(true);
    }
    fetchPages();
  }, []);

  async function fetchPages(): Promise<void> {
    try {
      dispatch({
        type: AppContextAction.PAGE_LOAD,
        pages: { state: LoadingState.LOADING },
      });
      const pageResponse: AxiosResponse<PageDto[]> = await getPages(false);
      dispatch({
        type: AppContextAction.PAGE_LOAD,
        pages: { state: LoadingState.LOADED, data: pageResponse.data },
      });
    } catch (error) {
      dispatch({
        type: AppContextAction.PAGE_LOAD,
        pages: { state: LoadingState.FAILED, errorMessage: error.message },
      });
    }
  }

  function handleCreatePageClick() {
    navigate("/templates");
  }

  const plusIcon = <BiPlus size="25" color={colors.white2} />;

  if (state.pages.state === LoadingState.LOADING) {
    return <Spinner />;
  }

  function websitesInfoText() {
    if (!pagesData?.length) {
      return t("you-have-no-website(s)");
    }
    if (pagesData?.length === 1) {
      return t("you-have-a-website");
    }
    return t("you-have-websites", { count: pagesData?.length });
  }

  function handleDiscountClick() {
    navigate("/upgrade");
  }

  return (
    <>
      {isSale && (
        <div className="discount" onClick={handleDiscountClick}>
          <div className="discount-content">
            <div className="discount-text">
              <span className="discount-highlight">50% OFF</span>
              <span className="discount-description">
              FOR ALL PLANS
              </span>
            </div>
          </div>
        </div>
      )}
      <AuthHeader showRegisterDeviceButton={isDeviceUser} />
      <div className="Dashboard-Container">
        <div className="Dashboard">
          {showUpgradeModal && (
            <UpgradeModal
              isAuth={true}
              onClose={() => {
                setShowUpgradeModal(false);
              }}
            />
          )}
          <div className="Dashboard-Title">
            <h1>Dashboard</h1>
            <h3>{websitesInfoText()}</h3>
          </div>
          <div className="WebPage-Item-List">
            {pagesData?.map?.((p, i) => (
              <WebPageItem
                templateName={p.templateName}
                pageId={p.id}
                key={p.id}
                index={pagesData.length - 1 - i}
                isPrivacyModeEnabled={p.isPrivacyModeEnabled}
              />
            ))}
          </div>
          <div className="Dashboard-Create">
            <div className="Dashboard-Create-New-Website-Btn">
              <DefaultButton
                icon={plusIcon}
                color="gradient"
                size="medium"
                text={t("new-website")}
                onClick={handleCreatePageClick}
              />
            </div>
          </div>
          <style jsx>
            {`
              .discount {
                width: 100%;
                display: flex;
                justify-content: center;
                background: linear-gradient(45deg, #ff6b6b, #4ecdc4);
                color: white;
                padding: 0.75rem;
                box-sizing: border-box;
                font-family: 'Inter', sans-serif;
                cursor: pointer;
                transition: opacity 0.2s ease;
              }
              .discount:hover {
                opacity: 0.9;
                cursor: pointer;
              }
              .discount-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.5rem;
              }
              .discount-text {
                display: flex;
                align-items: center;
                gap: 0.75rem;
              }
              .discount-highlight {
                font-size: 1.5rem;
                font-weight: 800;
                background: white;
                color: #ff6b6b;
                padding: 0.25rem 0.75rem;
                border-radius: 4px;
                text-shadow: 1px 1px 0px rgba(0,0,0,0.1);
              }
              .discount-description {
                font-size: 1.2rem;
                font-weight: 600;
                letter-spacing: 0.5px;
              }
              .discount-timer {
                font-size: 1.1rem;
                font-weight: 500;
                background: rgba(255,255,255,0.2);
                padding: 0.25rem 0.75rem;
                border-radius: 20px;
              }
              @media (max-width: 600px) {
                .discount-text {
                  flex-direction: column;
                  gap: 0.25rem;
                  text-align: center;
                }
                .discount-highlight {
                  font-size: 1.25rem;
                }
                .discount-description {
                  font-size: 1rem;
                }
                .discount-timer {
                  font-size: 0.9rem;
                }
              }
              .Dashboard-Container {
                background:
                linear-gradient(90deg, rgb(252, 230, 255), rgb(233, 234, 255));
                width: 100%;
                display: flex;
                justify-content: center;
              }
              .Dashboard {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-bottom: 8em;
                color: ${colors.white2};
                min-height: 100vh;
                max-width: 750px;
                width: 100%;
              }
              .WebPage-Item-List {
                margin-top: 2em;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 3em;
                padding: 1em 0.5em;
                box-sizing: border-box;
              }
              .Webpage-Item {
                max-width: 500px;
              }

              .Dashboard-Create {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: start;
                flex-direction: column;
                padding: 1em;
                box-sizing: border-box;
              }
              .Dashboard-Create-New-Website-Btn {
                width: 12em;
              }
              .Dashboard-Title {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: center;
                padding-left: 1em;
                box-sizing: border-box;
              }
              .Dashboard-Title > h1,
              h3 {
                background: -webkit-linear-gradient(
                  45deg, 
                  #827ffc, 
                  #fb7f82 80%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
            `}
          </style>
        </div>
      </div>
    </>
  );
};

export default Profile;
