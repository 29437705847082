import React, { useContext } from "react";
import DefaultButton from "../common/DefaultButton";
import { getCreatePageDto } from "./CreatePageHelper";
import { TemplateName } from "../../shared/Enums/TemplateName";
import { create } from "../../api/PageAPI";
import PageDto from "../../api/dtos/PageDto";
import { AppContext } from "../AppContext";
import { LoadingState } from "../../common/LoadingState";
import { createDeviceUser } from "../../api/UserApi";
import { useTranslation } from "react-i18next";
import { PageBlockSpinner } from "../common/PageBlockSpinner";
import { getTemplatePreview } from "../../shared/Types/TemplatePreviews";

type Props = {
  alignment?: "horizontal" | "vertical";
  className: string;
  templateName: TemplateName;
};

export const TemplateViewActions: React.FC<Props> = ({ alignment = "vertical", className, templateName }) => {
  const { state } = useContext(AppContext);
  const [isSpin, setIsSpin] = React.useState(false);
  const { t } = useTranslation();

  async function onEditClick() {
    if (state.userDto.state !== LoadingState.LOADED) {
      localStorage.setItem("templateName", templateName);
      const deviceId = window.crypto.randomUUID();
      localStorage.setItem("deviceId", deviceId);
      // @ts-ignore
      window.__deviceId = deviceId;
      await createDeviceUser(deviceId);
    }
    try {
      setIsSpin(true);
      const pageCreateDto = getCreatePageDto(templateName);
      const pageResponse = await create(pageCreateDto);
      const page: PageDto = pageResponse.data;
      window.location.href = `/edit?pageId=${page.id}`;
    } catch (error) {
      setIsSpin(false);
    }
  }

  const demoUrl = getTemplatePreview(templateName);

  return (
    <div className={className}>
      {isSpin && <PageBlockSpinner />}
      <DefaultButton
        className={`${className}-Action-2`}
        isSpin={isSpin}
        size="medium"
        text={t("start-with-this-template")}
        color="gradient"
        onClick={onEditClick}
      />
      <a className={`${className}-Button-Link`} href={demoUrl}>
        <DefaultButton className={`${className}-Action-1`} size="medium" color="lightBlue" text={t("preview")} onClick={() => null} />
      </a>

      <style>
        {`
                .${className} {
                    display: flex;
                    justify-content: space-around;
                    flex-direction: 
                    ${alignment === "horizontal" ? "row" : "column"};
                    margin-top: 0.5em;
                    gap: 0.5em;
                }
                .${className}-Button-Link {
                    text-decoration: none;
                }
            `}
      </style>
    </div>
  );
};
