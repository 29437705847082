import React, { useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";

interface ExpiryCountdownProps {
  expiryDate: Date;
  className?: string;
}

const useTimeDisplay = (expiryDate: Date, secondsRef: React.RefObject<HTMLSpanElement>) => {
  const timerRef = useRef<NodeJS.Timeout>();
  const displayRef = useRef<string>("");
  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);

  const calculateTimeLeft = useCallback(() => {
    const now = new Date();
    const difference = expiryDate.getTime() - now.getTime();

    if (difference <= 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        weeks: 0,
        years: 0,
      };
    }

    const years = Math.floor(difference / (1000 * 60 * 60 * 24 * 365));
    const weeks = Math.floor((difference % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 7));
    const days = Math.floor((difference % (1000 * 60 * 60 * 24 * 7)) / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { years, weeks, days, hours, minutes, seconds };
  }, [expiryDate]);

  const getTimeDisplay = useCallback((time: ReturnType<typeof calculateTimeLeft>) => {
    if (Object.values(time).every((v) => v === 0)) {
      return "Expired";
    }
    if (time.years > 0) {
      return null;
    }
    if (time.weeks > 0) {
      return `${time.weeks}w ${time.hours}h`;
    }
    if (time.days > 0) {
      return `${time.days}d ${time.hours}h`;
    }
    if (time.hours > 0) {
      return `${time.hours}h ${time.minutes}m`;
    }
    return `${time.minutes}m`;
  }, []);

  useEffect(() => {
    // Clear any existing timer
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    const initial = calculateTimeLeft();
    const initialDisplay = getTimeDisplay(initial) || "";
    displayRef.current = initialDisplay;

    // Initialize seconds display
    if (secondsRef.current && initial.hours === 0) {
      secondsRef.current.textContent = `${initial.seconds}s`;
    }

    // Force initial update
    forceUpdate();

    // Start timer if there's time left
    if (Object.values(initial).some((v) => v > 0)) {
      timerRef.current = setInterval(() => {
        const newTimeLeft = calculateTimeLeft();
        const newDisplay = getTimeDisplay(newTimeLeft);

        if (newDisplay !== displayRef.current) {
          displayRef.current = newDisplay || "";
          forceUpdate();
        }

        if (secondsRef.current && newTimeLeft.hours === 0) {
          secondsRef.current.textContent = `${newTimeLeft.seconds}s`;
        }

        if (Object.values(newTimeLeft).every((v) => v === 0)) {
          if (timerRef.current) {
            clearInterval(timerRef.current);
            timerRef.current = undefined;
          }
        }
      }, 1000);

      return () => {
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }
      };
    }
  }, [calculateTimeLeft, getTimeDisplay]);

  return displayRef.current;
};

export const ExpiryCountdown: React.FC<ExpiryCountdownProps> = React.memo(({ expiryDate, className }) => {
  const navigate = useNavigate();
  const secondsRef = useRef<HTMLSpanElement>(null);
  const timeDisplay = useTimeDisplay(expiryDate, secondsRef);

  const handleClick = useCallback(() => {
    navigate("/upgrade");
  }, [navigate]);

  if (!timeDisplay) {
    return null;
  }

  return (
    <div
      className={`expiry-countdown ${className || ""}`}
      onClick={handleClick}
      style={{ cursor: "pointer" }}
    >
      <div className="expiry-content">
        <div className="expiry-text">
          <span className="expiry-description">
            {timeDisplay === "Expired" ? "Expired" : `Expires in ${timeDisplay}`}
            {timeDisplay !== "Expired" && timeDisplay.includes("m") && !timeDisplay.includes("h") && (
              <span ref={secondsRef} className="expiry-seconds" />
            )}
          </span>
        </div>
        <div className="expiry-cta">
          <span className="expiry-cta-text">Upgrade Now</span>
          <span className="expiry-cta-arrow">→</span>
        </div>
      </div>
      <style jsx>{`
         .expiry-countdown {
          position: absolute;
          top: -55px;
          right: 0;
          background: linear-gradient(135deg, #FF6B6B, #4ECDC4);
          color: white;
          padding: 8px 16px;
          border-radius: 8px;
          box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
          transition: all 0.3s ease;
          z-index: 0.5;
          font-family: 'Inter', sans-serif;
          min-width: 200px;
          -webkit-tap-highlight-color: transparent;
          touch-action: manipulation;
          user-select: none;
        }

        .expiry-countdown:hover {
          transform: translateY(-2px);
          box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
        }

        .expiry-content {
          display: flex;
          align-items: center;
          gap: 12px;
        }

        .expiry-text {
          display: flex;
          flex-direction: column;
          gap: 2px;
        }

        .expiry-description {
          font-size: 0.85rem;
          font-weight: 500;
        }

        .expiry-cta {
          display: flex;
          align-items: center;
          gap: 4px;
          background: rgba(255, 255, 255, 0.2);
          padding: 4px 8px;
          border-radius: 4px;
          transition: all 0.2s ease;
        }

        .expiry-cta:hover {
          background: rgba(255, 255, 255, 0.3);
        }

        .expiry-cta-text {
          font-size: 0.85rem;
          font-weight: 600;
        }

        .expiry-cta-arrow {
          font-size: 1rem;
          transition: transform 0.2s ease;
        }

        .expiry-countdown:hover .expiry-cta-arrow {
          transform: translateX(2px);
        }

        @media (max-width: 500px) {
          .expiry-countdown {
            top: -34%;
            right: 26%;
            transform: translateX(50%);
            width: 90%;
            max-width: 300px;
            min-width: unset;
            touch-action: none;
          }

          .expiry-countdown:hover {
            transform: translateX(50%) translateY(-2px);
          }

          .expiry-content {
            flex-direction: column;
            text-align: center;
            gap: 8px;
          }

          .expiry-cta {
            width: 100%;
            justify-content: center;
          }
        }

        @media (max-width: 500px) {
          .expiry-countdown {
            padding: 6px 12px;
            min-width: unset;
            width: auto;
            max-width: 90%;
          }

          .expiry-content {
            flex-direction: row;
            gap: 8px;
            align-items: center;
          }

          .expiry-text {
            flex-direction: row;
            align-items: center;
            gap: 8px;
          }

          .expiry-description {
            font-size: 0.8rem;
            white-space: nowrap;
          }

          .expiry-cta {
            background: none;
            padding: 0;
            margin-left: 4px;
            position: relative;
          }

          .expiry-cta-text {
            font-size: 0.8rem;
            color: #fff;
            font-weight: 700;
          }

          .expiry-cta-arrow {
            font-size: 1.1rem;
            margin-left: 2px;
            display: inline-block;
            animation: bounceRight 1s infinite;
          }

          @keyframes bounceRight {
            0%, 100% {
              transform: translateX(0);
            }
            50% {
              transform: translateX(3px);
            }
          }

          .expiry-countdown:hover .expiry-cta-arrow {
            animation: none;
            transform: translateX(2px);
          }
        }

        .expiry-seconds {
          margin-left: 2px;
          display: inline-block;
          min-width: 2.5em;
        }

        @media (max-width: 500px) {
          .expiry-seconds {
            margin-left: 0;
            min-width: 2em;
          }
        }
      `}</style>
    </div>
  );
});

ExpiryCountdown.displayName = "ExpiryCountdown";
