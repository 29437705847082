import React from "react";
import colors from "../colors";
import { FaSpinner } from "react-icons/fa";

type Props = {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onBlur?: () => void;
  text: string;
  isSpin?: boolean;
  icon?: React.ReactNode;
  iconPosition?: "left" | "right";
  size?: "small" | "medium" | "large";
  className?: string;
  disabled?: boolean;
  color?: "white" | "blue" | "green" | "purple" | "lightBlue" | "gradient";
};

const DefaultButton: React.FC<Props> = ({
  onClick,
  text,
  isSpin = false,
  icon = null,
  size = "large",
  className = "DefaultButton",
  disabled = false,
  color = "white",
  iconPosition = "left",
  onBlur = () => null,
}) => {
  // row-reverse is used to reverse the order of the icon and text

  const buttonFlexDirection = iconPosition === "left" ? "row" : "row-reverse";
  const fontSize = size === "small" ? "12px" : size === "medium" ? "16px" : "20px";
  const borderRadius = size === "small" ? "3px" : size === "medium" ? "5px" : "10px";
  const borderSize = size === "small" ? "0.1px" : "1px";
  const paddingSize = size === "small" ? "4px" : "0.5em 1em";

  const backgroundColor =
    color === "white"
      ? "rgb(242, 242, 247, 0.7)"
      : color === "blue"
      ? colors.blue
      : color === "green"
      ? colors.green
      : color === "lightBlue"
      ? colors.lightBlue
      : color === "gradient"
      ? "linear-gradient(135deg, rgba(255, 0, 0, 0.5), rgba(0, 0, 255, 0.5));"
      : colors.premiumPurple;

  const fontColor = color === "white" ? "#000" : colors.white2;

  const spinnerSize = size === "small" || size === "medium" ? "20" : "40";

  return (
    <>
      <button onBlur={onBlur} disabled={disabled || isSpin} onClick={onClick} className={`${className}-Container`}>
        {isSpin && <FaSpinner className={`${className}Spinner`} size={spinnerSize} color={colors.white2} />}

        {isSpin === false && !!icon && <div className={`${className}-Icon`}>{icon}</div>}

        <span>{text}</span>
      </button>
      <style>
        {`
         @keyframes spinner {
          0% {
            transform: translate3d(0, 0, 0) rotate(0deg);
          }
          100% {
            transform: translate3d(0, 0, 0) rotate(360deg);
          }
        }
        .${className}-Container:hover {
          cursor: ${disabled ? "not-allowed" : "pointer"};
          text-shadow: 0 0 1px ${colors.white};
        }
        .${className}-Container {
          cursor: pointer;
          padding: ${paddingSize};
          background: ${backgroundColor};
          font-size: ${fontSize};
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: ${borderRadius};
          border: ${borderSize} solid ${fontColor};
          width: 100%;
          flex-direction: ${buttonFlexDirection};
          box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
          box-sizing: border-box;
          height: 100%;
        }
        .${className}-Container > span {
          color: ${fontColor};
          font-weight: bold;
        }
        .${className}Spinner {
          animation: 1.5s linear infinite spinner;
        }
        .${className}-Icon {
          margin-right: 0.5em;
          display: flex;
          align-items: center;
        }
        .${className}-Container:disabled {
          background-color: rgb(0, 0, 0, 0.25);
          border: none;
        }
      `}
      </style>
    </>
  );
};

export default DefaultButton;
