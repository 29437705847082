import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import axios from "axios";
import { AppContextProvider } from "./components/AppContext";
import "./assets/lovepage.theme.css";

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause
    // this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    const errorJson = error.toJSON();
    const allowedPaths = [
      "/sign-in",
      "/sign-in-link",
      "/sign-in-link-sent",
      "/",
      "/terms-and-conditions",
      "/password-reset",
      "/set-password",
      "/pricing",
      "/contact-us",
      "/templates",
      "/subscribe",
      "/unsubscribe",
    ];
    if (errorJson.status === 403 && !allowedPaths.includes(window.location.pathname)) {
      window.location.href = "/sign-in";
    }
    // Any status codes that falls outside
    // the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(
  function (request) {
    // Any status code that lie within the range of 2xx cause
    // this function to trigger
    // Do something with response data
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <React.StrictMode>
    <AppContextProvider>
      <App />
    </AppContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
