import React, { useContext, useEffect, useState } from "react";
import PageDto from "../../api/dtos/PageDto";
import { LoadingState } from "../../common/LoadingState";
import { ViewState } from "../../common/ViewState";
import { Spinner } from "../common/Spinner";
import { SharePageButton } from "./SharePageButton";
import { ViewPageState } from "./state/ViewPageState";
import { PageItemType } from "../edit/dto/PageItemType";
import { generateRandomString } from "../../common/GenerateHash";
import { TemplateName } from "../../shared/Enums/TemplateName";
import { AnniversaryPage } from "./theme/AnniversaryPage";
import { TimelinePage } from "./theme/timeline/TimelinePage";
import { observer } from "mobx-react-lite";
import colors from "../colors";
import { AppContext } from "../AppContext";
import { getPublicPage } from "../../api/PageAPI";
import PasswordInputDialog from "../common/PasswordInputDialog";
import { PasswordStorage } from "../../utils/PasswordStorage";
import { useTranslation } from "react-i18next";
import TheLoveTimesPage from "./theme/loveTimes/TheLoveTimesPage";

type Props = {
  subdomain: string;
};

const viewPageState = new ViewPageState();

const Page: React.FC<Props> = observer(({ subdomain }) => {
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.LOADING);
  const [error, setError] = useState<string | null>(null);
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();
  const { state } = useContext(AppContext);
  const isAuthenticated = state.userDto.state === LoadingState.LOADED;

  useEffect(() => {
    if (!subdomain) return;

    const loadPage = async () => {
      try {
        setLoadingState(LoadingState.LOADING);
        const response = await getPublicPage(subdomain);

        // Update view page state with the response data
        viewPageState.setItems(
          [...response.data.photos, ...response.data.quotes]
            .sort((a, b) => a.pageIndex - b.pageIndex)
            .map((item) => {
              if ("data" in item) {
                return {
                  type: PageItemType.PHOTO,
                  data: item.data,
                  key: generateRandomString(),
                };
              } else {
                return {
                  type: PageItemType.QUOTE,
                  data: item.text,
                  key: generateRandomString(),
                };
              }
            })
        );
        viewPageState.setIsExpired(response.data.isExpired);
        viewPageState.setHasActivePlan(response.data.hasActivePlan);
        viewPageState.setTemplateName(response.data.templateName);
        viewPageState.setIsPrivacyModeEnabled(response.data.isPrivacyModeEnabled);
        viewPageState.setEnabledPages(response.data.enabledPages);

        setLoadingState(LoadingState.LOADED);
      } catch (error: any) {
        console.error("Page load error:", error);
        if (error.response?.status === 405) {
          const errorType = error.response.data?.error;
          console.log("Password error type:", errorType);
          if (errorType === "requires-password" || errorType === "wrong-password") {
            setShowPasswordDialog(true);
            setError(errorType === "wrong-password" ? t("Wrong password. Please try again.") : null);
          }
        } else {
          setError(t("Failed to load page. Please try again later."));
        }
        setLoadingState(LoadingState.ERROR);
      }
    };

    loadPage();
  }, [subdomain, t]);

  const handlePasswordSubmit = async (password: string) => {
    setIsSubmitting(true);
    try {
      // Save password to session storage BEFORE making the request
      await PasswordStorage.savePassword(subdomain, password);

      // Reload page with new password
      const response = await getPublicPage(subdomain);

      // Update view page state with the response data
      viewPageState.setItems(
        [...response.data.photos, ...response.data.quotes]
          .sort((a, b) => a.pageIndex - b.pageIndex)
          .map((item) => {
            if ("data" in item) {
              return {
                type: PageItemType.PHOTO,
                data: item.data,
                key: generateRandomString(),
              };
            } else {
              return {
                type: PageItemType.QUOTE,
                data: item.text,
                key: generateRandomString(),
              };
            }
          })
      );
      viewPageState.setIsExpired(response.data.isExpired);
      viewPageState.setHasActivePlan(response.data.hasActivePlan);
      viewPageState.setTemplateName(response.data.templateName);
      viewPageState.setIsPrivacyModeEnabled(response.data.isPrivacyModeEnabled);
      viewPageState.setEnabledPages(response.data.enabledPages);

      setLoadingState(LoadingState.LOADED);
      setShowPasswordDialog(false);
    } catch (error: any) {
      console.error("Password submit error:", error);
      if (error.response?.status === 405) {
        setError(t("Wrong password. Please try again."));
        // Clear the wrong password from storage
        PasswordStorage.removePassword(subdomain);
      } else {
        setError(t("Failed to load page. Please try again later."));
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const isShareAvailable = !!navigator.share;

  const renderPasswordDialog = () => {
    if (!showPasswordDialog) return null;

    return <PasswordInputDialog onSubmit={handlePasswordSubmit} isSpin={loadingState === LoadingState.LOADING} error={error} />;
  };

  const renderContent = () => {
    switch (loadingState) {
      case LoadingState.IDLE:
      case LoadingState.LOADING:
        return (
          <>
            <Spinner />
          </>
        );
      case LoadingState.LOADED:
        return (
          <>
            <div className="Page">
              {viewPageState.templateName === TemplateName.ANNIVERSARY && <AnniversaryPage page={viewPageState} isAuthenticated={isAuthenticated} />}
              {viewPageState.templateName === TemplateName.TIMELINE && <TimelinePage page={viewPageState} isAuthenticated={isAuthenticated} />}
              {viewPageState.templateName === TemplateName.THE_LOVE_TIMES && (
                <TheLoveTimesPage page={viewPageState} isAuthenticated={isAuthenticated} />
              )}
              {isShareAvailable && (
                <div className="Page-Share-Button">
                  <SharePageButton />
                </div>
              )}
              <style>
                {`
                  .Page {
                    display: flex;
                    flex-direction: column;
                    padding: 4em 0 5em 0;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    background: ${colors.purpleBlueGradient};
                  }
                  .Page-Share-Button {
                    display: flex;
                    justify-content: center;
                    padding: 5em 1em 1em 0;
                  }
                `}
              </style>
            </div>
          </>
        );
      case LoadingState.ERROR:
        return (
          <>
            <div className="Page-Error">
              <h4>{error || t("Failed to load page")}</h4>
              <style>
                {`
                  .Page-Error {
                    display: flex;
                    justify-content: center;
                    color: white;
                  }
                `}
              </style>
            </div>
          </>
        );
      case LoadingState.FAILED:
        return (
          <>
            <div className="Page-Error">
              <h4>This page doesn&apos;t exist :(</h4>
              <style>
                {`
                  .Page-Error {
                    display: flex;
                    justify-content: center;
                    color: white;
                  }
                `}
              </style>
            </div>
          </>
        );
      default:
        return (
          <>
            <div></div>
          </>
        );
    }
  };

  return (
    <>
      {renderContent()}
      {renderPasswordDialog()}
    </>
  );
});

export default Page;
