import React, { ChangeEvent, useState } from "react";
import Input from "../common/Input";
import DefaultButton from "../common/DefaultButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import ErrorMessage from "../ErrorMessage";
import colors from "../colors";
import { validatePassword } from "../auth/Validator.util";
import { postSetPassword } from "../../api/AuthApi";

const SetPassword: React.FC = () => {
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState("");
  const [isSaveBtnSpin, setSaveBtnSpin] = useState(false);
  const [isRequestANewLink, setIsRequestANewLink] = useState(false);
  const navigate = useNavigate();
  const token = searchParams.get("t");

  const isPasswordSet = !!searchParams.has("confirmation");

  function handlePasswordChange(val: ChangeEvent<HTMLInputElement>) {
    setServerErrorMessage("");
    setPasswordErrorMessage("");
    setPassword(val.target.value);
  }

  async function handleSendClick() {
    const result = validatePassword(password);
    if (result) {
      setPasswordErrorMessage(result);
      return;
    }
    try {
      setSaveBtnSpin(true);
      await postSetPassword(token, password);
      navigate("/set-password?confirmation");
    } catch (error) {
      setServerErrorMessage("Your password reset link has expired." + " Please request a new one.");
      setIsRequestANewLink(true);
    } finally {
      setSaveBtnSpin(false);
    }
  }

  if (isPasswordSet) {
    return (
      <div className="SetPassword">
        <h3>Password changed successfully</h3>
        <a className="SetPassword-SignIn-Link" href="/sign-in">
          Click here to Sign In
        </a>
        <style>
          {`
                .SetPassword {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    color: ${colors.white2}
                }
                .SetPassword-SignIn-Link {
                    color: ${colors.white2};
                    text-decoration: underline;
                    font-size: 1.5em;
                }
            `}
        </style>
      </div>
    );
  }

  return (
    <div className="SetPassword">
      <h3 className="SetPassword-Header">Set a new Password</h3>
      <div className="SetPassword-Form">
        {!!serverErrorMessage && <ErrorMessage message={serverErrorMessage} />}
        {!!passwordErrorMessage && <div className="SetPassword-Password-Week">❌ {passwordErrorMessage}</div>}
        {!isRequestANewLink && (
          <>
            {" "}
            <Input
              password={true}
              isError={!!serverErrorMessage || !!passwordErrorMessage}
              defaultValue=""
              onChange={handlePasswordChange}
              placeholder="New Password"
              autoComplete="new-password"
            />
            <div className="SetPassword-Form-Button">
              <DefaultButton isSpin={isSaveBtnSpin} text="Save Password" onClick={handleSendClick} />
            </div>
          </>
        )}
        {isRequestANewLink && (
          <a className="Request-New-Link" href="/password-reset">
            Click here to request a new reset link
          </a>
        )}
      </div>
      <style>
        {`
            .SetPassword {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                text-align: left;
                gap: 1em;
                width: 100%;
                color: ${colors.white2};
                height: 50vh;
            }
            .SetPassword-Header {
                font-size: 1.5em;
                padding: 0 1em 1em 1em;
            }   
            .SetPassword-Form {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 1em;
                padding: 1em;
            }
            .SetPassword-Form-Button {
                width: 100%;
            }
            .SetPassword-Password-Week {
                color: ${colors.red};
                font-weight: bold;
            }
            .Request-New-Link {
                color: ${colors.white2};
            }
        `}
      </style>
    </div>
  );
};

export default SetPassword;
