import React from "react";
import { useTranslation } from "react-i18next";
import colors from "../colors";
import { IoShieldCheckmark } from "react-icons/io5";

export const SetPrivacyPasswordDialogBody: React.FC = () => {
  const { t } = useTranslation();
  
  return <div className="SetPrivacyPasswordDialogBody">
    <div className="SetPrivacyPasswordDialogBody-Icon">
      <IoShieldCheckmark size={32} color={colors.white2} />
    </div>
    <div className="SetPrivacyPasswordDialogBody-Title">
      {t("Enable privacy mode")}
    </div>
    <div className="SetPrivacyPasswordDialogBody-Description">
      {t("Password must be at least 6 characters long")}
    </div>
    <style>
      {`
        .SetPrivacyPasswordDialogBody-Title {
          font-size: 1.5em;
          margin-bottom: 0.5em;
          padding: 1em 1em 0.5em 1em;
          text-align: center;
          font-weight: bold;
        }
        .SetPrivacyPasswordDialogBody-Description {
          font-size: 1.1em;
          color: ${colors.white2};
          padding: 0 1em 1em 1em;
          text-align: center;
        }
        .SetPrivacyPasswordDialogBody { 
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .SetPrivacyPasswordDialogBody-Icon {
          padding-top: 1em;
        }
      `}
    </style>
  </div>
} 