import { css } from "@emotion/css";
import React from "react";
import { useTranslation } from "react-i18next";
import LoveBox from "../LoveBox/LoveBox";

export const SecondFeatureColumns = () => {
  const { t } = useTranslation();

  const featuresStyle = css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4.5em;
    justify-content: flex-start;
    width: 100%;
    padding: 5em 2em;
    box-sizing: border-box;
    .Feature-Icon > img {
      border-radius: 10%;
    }
    .surprise-img {
      width: 200px;
      height: 200px;
    }
    .surprise-img-qr {
      width: 100%;
      max-width: 350px;
    }
    .accesible-img {
      width: 200px;
      height: 160px;
    }
    .your-address-img {
      width: 300px;
      height: 200px;
    }
    .Feature-Header {
      font-size: 1.5em;
      margin: 0;
      font-weight: bold;
    }
    .Feature-Text {
      font-size: 1em;
      margin: 0;
      font-family: "Source Sans Pro", sans-serif;
      color: #777777;
      line-height: 1.5em;
    }
  `;

  return (
    <div className={featuresStyle}>
      <LoveBox>
        <div className="Feature-Icon">
          <img
            className="surprise-img surprise-img-qr"
            src="https://ik.imagekit.io/ex5o5cwj51/qr-envelope-lp.png?updatedAt=1692714300336"
            alt="Surprise"
          />
        </div>
        <h4 className="Feature-Header">{t("qr-love-surprises")}</h4>
        <p className="Feature-Text">{t("qr-love-surprises-sub")}</p>
      </LoveBox>
      <LoveBox>
        <div className="Feature-Icon">
          <img
            className="your-address-img"
            src="https://ik.imagekit.io/ex5o5cwj51/your-domain.jpeg?updatedAt=1692721004017"
            alt="You can choose your own subdomain"
          />
        </div>
        <h4 className="Feature-Header">
          <span style={{ color: "#ffadad" }}>{t("yourname")}.</span>lovepage.io
        </h4>
        <p className="Feature-Text">{t("yourname-lovepage.io-sub")}</p>
      </LoveBox>
      <LoveBox>
        <div className="Feature-Icon">
          <img className="accesible-img" src="https://ik.imagekit.io/ex5o5cwj51/accessible.png?updatedAt=1687421824495" alt="Accessible everywhere" />
        </div>
        <h4 className="Feature-Header">{t("accessible-everywhere")}</h4>
        <p className="Feature-Text">{t("accessible-everywhere-sub")}</p>
      </LoveBox>
    </div>
  );
};
