import axios, { AxiosResponse } from "axios";
import PageEditDto from "./dtos/PageEditDto";
import PageCreateDto from "./dtos/PageCreateDto";
import PageDto from "./dtos/PageDto";
import PageSubdomainUpdateDto from "./dtos/PageSubdomainUpdateDto";
import { PasswordStorage } from "../utils/PasswordStorage";


export function create(dto: PageCreateDto): Promise<AxiosResponse<PageDto>> {
  return axios.post("/api/page/create", dto, { withCredentials: true });
}

export function getPages(cache = true): Promise<AxiosResponse<PageDto[]>> {
  return axios.get(`/api/page/me?date=${cache ? "" : new Date().getTime()}`
      , { withCredentials: true });
}

export function update(dto: PageEditDto): Promise<AxiosResponse> {
  return axios.put("/api/page/update", dto, { withCredentials: true });
}

export function updateSubdomain(dto: PageSubdomainUpdateDto):
 Promise<AxiosResponse> {
  return axios.put("/api/page/update-subdomain",
      dto, { withCredentials: true });
}
export function deletePage(pageId: number): Promise<AxiosResponse> {
  return axios.delete(`/api/page/delete/${pageId}`, { withCredentials: true });
}

export async function getPublicPage(subdomain: string):
        Promise<AxiosResponse<PageDto>> {
  const password = await PasswordStorage.getPassword(subdomain);
  const headers = password ? { "x-with-password": password } : {};

  try {
    const response = await axios.get(`/api/page/view/${subdomain}`,
        { headers });
    return response;
  } catch (error: any) {
    if (error.response?.status === 405) {
      const errorType = error.response.data?.error;
      if (errorType === "wrong-password" || errorType === "requires-password") {
        // Clear stored password if wrong
        await PasswordStorage.removePassword(subdomain);
      }
      // Let the component handle both
      //  wrong-password and requires-password cases
    }
    throw error;
  }
}

export function setPrivacyMode(pageId: number,
    enabled: boolean, password?: string): Promise<AxiosResponse> {
  return axios.put("/api/page/set-privacy-mode",
      { pageId, enabled, password }, { withCredentials: true });
}

