import React, { useState } from "react";
import styles from "./AnimatedProfiles.module.css";

interface AnimatedProfilesProps {
  profiles: string[];
  positions: ("top" | "bottom")[];
}

const AnimatedProfiles: React.FC<AnimatedProfilesProps> = React.memo(({ profiles, positions }) => {
  // Shuffle profiles array only once when component mounts
  const [shuffledProfiles] = useState(() => [...profiles].sort(() => Math.random() - 0.5));
  const [shuffledPositions] = useState(() => shuffledProfiles.map(() => Math.random() > 0.5 ? "top" : "bottom"));
  
  // Duplicate shuffled profiles to create seamless infinite animation
  const extendedProfiles = [...shuffledProfiles, ...shuffledProfiles];
  const extendedPositions = [...shuffledPositions, ...shuffledPositions];

  return (
    <div className={styles.profileContainer}>
      {extendedProfiles.map((src, index) => (
        <img
          key={`${src}-${index}`}
          src={src}
          alt="User"
          className={`${styles.profileImg} ${styles[extendedPositions[index]]}`}
          style={{
            animationDelay: `${index * 3}s`,
            animationDuration: `${profiles.length * 3}s`,
            animationIterationCount: "infinite",
          }}
        />
      ))}
    </div>
  );
});

AnimatedProfiles.displayName = "AnimatedProfiles";

export default AnimatedProfiles; 