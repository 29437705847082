import React, { useContext, useState } from "react";
import { AppContext } from "../AppContext";
import { LoadingState } from "../../common/LoadingState";
import { Spinner } from "../common/Spinner";
import { useNavigate } from "react-router-dom";
import AuthHeader from "../header/AuthHeader";
import { FAQ } from "../homepage/FAQ";
import { SupportForm } from "./SupportForm";
import styles from "./styles.module.css";

const Support: React.FC = () => {
  const { state } = useContext(AppContext);
  const navigate = useNavigate();

  const help = state.userDto.state === LoadingState.LOADED && (
    <>
      <AuthHeader />
      <div className={styles.support}>
        <SupportForm />
        <FAQ />
      </div>
    </>
  );

  switch (state.userDto.state) {
    case LoadingState.IDLE:
      return null;
    case LoadingState.LOADING:
      return <Spinner />;
    case LoadingState.LOADED:
      return help;
    case LoadingState.NO_AUTH:
      navigate("/sign-in");
      return <Spinner />;
    default:
      return <Spinner />;
  }
};

export default Support;
